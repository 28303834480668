import { Container, Grid } from '@material-ui/core'
import React from 'react'
import styles from './caseStudy.module.sass'
import { Link } from "react-router-dom";

interface caseStudyProps{
    img:string;
    heading:string;
    paragraph:string;
    bg:string;
    classN:string;
    contentContainer:string;
    justifyP: string;
    btn_title: string;
    btn_link: string;
  }
const CaseStudy=(props:caseStudyProps)=>{
    return(
        <div  className={styles.caseStudyContainer} style={{backgroundImage: `url(${props.bg})`}}>
            <Container>
            <Grid container className={props.justifyP} >
                <Grid item lg={6} xs={12}>
            <div className={props.contentContainer}>
            <img src={props.img} alt=""/>
            <h2>{props.heading}</h2>
            <p>{props.paragraph}</p>
            <Link to={`${props.btn_link}`}>
            <button className={props.classN} >
            {props.btn_title} </button>
            </Link>
            </div>
            </Grid>
            </Grid>
            </Container>
        </div>
    )
}
export default CaseStudy;