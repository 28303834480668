import { Container } from "@material-ui/core";
import React from "react";
import styles from './thankYou.module.sass';

// interface IColor{
//     updateHeaderColor: (color: string ) => void;
//   }
const ThankYou = () => {
    // const sectionContainer = useRef<HTMLDivElement>(null);
    // let textColor = sectionContainer.current?.dataset.color ?? '#fff';
    // useEffect(() => {
    //     props.updateHeaderColor(textColor)

    // })
    return(
        <div className={styles.thankYouContainer}>
            <Container>
                <div className={styles.thankYouContent}>
            <h3>THANK YOU!</h3>
            <span>We will get back to you soon.</span>
            </div>
            </Container>
        </div>
    )
}
export default ThankYou;