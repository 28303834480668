import axios from "../../axios";
import { EReduxActionTypes } from "../types/defaultTypes";
import { IServicesSection } from "../types/index";
import { Dispatch } from "redux";

function ServicesData(servicesData: IServicesSection[]) {
	return {
		type: EReduxActionTypes.SERVICES_DATA,
		servicesData,
	};
}

export function getServicesData() {
	return (dispatch: Dispatch) => {
		return new Promise<void>((resolve, reject) => {
			axios.get("/services-sections").then((response) => {
				// console.log(response);
				let data = response.data;
				dispatch(ServicesData(data));
				resolve();
			});
		});
	};
}
