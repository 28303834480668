import React from "react";
import { Container,Grid } from '@material-ui/core';
import styles from './websiteShowcase.module.sass';
import bg from '../../../imgs/cairokee-website-showcase-img.jpeg';
import storeTop from '../../../imgs/cairokee-store-top-image.jpeg';
import storeAll from '../../../imgs/cairokee-store-all-products-image.jpeg';

const WebsiteShowcase=()=>{
    return(
        <div className={styles.websiteShowcaseContainer}>
            <Container>
                <Grid container justify="center">
                    <Grid item xs={12} style={{textAlign:"center"}}>
                        <h2 className={styles.title}> Website Showcase</h2>
                    </Grid>
                    <Grid item xs={4}>
                    <h3 className={styles.challenge}>Challenge</h3>
                    <p className={styles.challengeContent}>For over 13 years, Cairokee has built a solid community of fans all across Egypt and the Arab world, so the real challenge was being the 1st band to sell products to this audience.</p>
                    <h3 className={styles.challenge}>Building Cairokee online store from scratch</h3>
                    <p className={styles.challengeContent}>To achieve its goal, their online store had to be easy mouse, frequently updated with Cairokee’s various products, and above all aligned with the band’s identity.</p>
                    </Grid>
                    <Grid item xs={7}>
                        <img src={bg} alt=""/>
                    </Grid>
                </Grid>
            </Container>
           <div className={styles.purchase}>
               <Container maxWidth="lg">
                   <Grid container justify="center" spacing={3}>
                       <Grid item xs={12}>
                           <h3 className={styles.purchaseTitle}>many purchase options. just one click</h3>
                       </Grid>
                       <Grid item xs={5}>
                           <div>                           <img src={storeTop} alt=""/>
</div>
<div className={styles.paragraphContent}>
<p className={styles.purchaseTitle}>Vivid e-store for all kinds people!</p>
                           <p className={styles.purchaseParagraph}>To achieve its goal, their online store had to be easy mouse, frequently updated with Cairokee’s various products, and above all aligned with the band’s identity.</p>
</div>
                         
                           </Grid>
                       <Grid item xs={6}>
                       <div className={styles.paragraphContent}>
                           <div><img src={storeAll} alt=""/></div>
                           
                           <p className={styles.purchaseParagraph}>The website’s been designed to make the purchasing process as easy as possible. Anyone who visits the website has the capability to get in contact with the merchandise line’s customer support.</p>
                           </div>
                           </Grid>
                   </Grid>
               </Container>
           </div>
           <div className={styles.colorDiv}></div>
        </div>
    )
}
export default WebsiteShowcase;