import { combineReducers } from 'redux'
import homeData from './home'
import aboutData from './about'
import isLoading from './loading'
import caseStudyData from './caseStudy'
import newsData from './news'
import servicesData from './services'
import dominosData from './dominos'
import jobsData from './jobs'


const root = combineReducers({
    homeData,
    aboutData,
    caseStudyData,
    newsData,
    servicesData,
    dominosData,
    isLoading,
    jobsData
})

export type AppState = ReturnType<typeof root>

export default root