import React, { memo } from "react";
import styles from "./contactSection.module.sass";
import { Container, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

interface IContactSection {
	// sectionNumber: string;
	// sectionSubTitle: string;
	quote: string;
	link: {
		linkText: string;
		linkUrl: string;
	};
}

function ContactSection(props: IContactSection) {
	return (
		<div className={styles.contactSectionContainer}>
			<Container>
				<Grid container>
					<Grid item xs={12} md={6}>
						{/* <p> */}
							{/* <span>{props.sectionNumber}</span> */}
							{/* {props.sectionSubTitle} */}
						{/* </p> */}
						<p>{props.quote}</p>
						<Link to='/contactUs'>	<button>{props.link.linkText}</button></Link>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}

export default memo(ContactSection);
