import React from "react";
import { Container,Grid } from '@material-ui/core';
import styles from './webDevelopment.module.sass';
import bg from '../../../imgs/dove-website-powered-by-humans.jpeg';
import bgTwo from '../../../imgs/dove-engaging-stories-mobile-img-min.jpeg';



const WebDevelopment=()=>{
    return(
        <div className={styles.webDevelopmentContainer}>
            <Container>
                <Grid container justify="center">
                    <Grid item xs={12} style={{textAlign:"center"}}>
                        <h2 className={styles.title}> Web Development</h2>
                    </Grid>
           
                    <Grid item xs={12} style={{textAlign:"center"}}>
                        <h3 className={styles.subTitle}>A website powered by humans</h3>
                        <p className={styles.paragraph}>As a global anchor in the beauty & personal care market, Dove has always postioned itself as a brand that genuinely cares about what real women are all about. Our main challenge was to promote Dove’s Intensive Repair by creating</p>
                    </Grid>
                    <Grid item xs={12}>
                        <div><img src={bg} alt=""/></div>
</Grid>
                    </Grid>

            </Container> 
            <div className={`${styles.bg} ${styles.second}`}>
                <Grid container >
                    <Grid item md={5} xs={12}>
                        <h3 className={styles.subTitle}>Engaging stories of real beauty </h3>
                        <p className={styles.paragraph}>Through “Dovemashreq.com” we expanded the idea of just having a website that demonstrates and sells products into an intuitive experience…</p>
                        <p className={styles.paragraph}>A splendid assortment of pictures told the story of daily challenges overcome by real women across the country, personal success experiences wrote by App users are automatically uploaded from the mobile App to the website’s blog, and products that communicated more than just personal care theories.</p>
<p className={styles.quote}>Throughout the whole campaign,<br/> we kept a close eye on the website’s analytics,<br/> because stats don’t lie…</p>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={styles.bgMobile}><img src={bgTwo} alt=""/></div>
                    </Grid>
            </Grid>
            </div>          
            
        </div>
    )
}
export default WebDevelopment;