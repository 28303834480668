import { Dispatch } from "redux";
import { EReduxActionTypes } from "../types/defaultTypes";

// interface ILoading {
// 	type: EReduxActionTypes.LOADING;
// 	data: boolean;
// }

function Loading(loading: boolean) {
	return {
		type: EReduxActionTypes.LOADING,
		loading,
	};
}

export function isLoading(isLoading: boolean) {
	return (dispatch: Dispatch) => dispatch(Loading(isLoading));
}
