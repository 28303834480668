import React from 'react'
import styles from "./headerLogoSvg.module.sass"

// interface ILogo{
//   fill: string | undefined
// }
export default function Logo() {
  return (
    <div className={`${styles.imgLogoHeader}`}>
    <svg xmlns="http://www.w3.org/2000/svg"  width="230.075" height="35.949" viewBox="0 0 230.075 35.949">
  <defs>
    <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
      <stop offset="0" stopColor="#fff100"/>
      <stop offset="0.498" stopColor="#fff10a"/>
      <stop offset="1" stopColor="gold"/>
    </linearGradient>
  </defs>
  <g id="TAC_UNIVERSE" data-name="TAC UNIVERSE" transform="translate(-251 -429.655)">
    <g id="Group_15" data-name="Group 15" transform="translate(278.527 461.725)">
      <path id="Path_62" data-name="Path 62" d="M423.261,630.45l-.872-1.226a2.769,2.769,0,0,1-.305.016H421.06v1.21h-.4v-3.814h1.427a1.724,1.724,0,0,1,1.144.349,1.187,1.187,0,0,1,.414.959,1.24,1.24,0,0,1-.226.755,1.252,1.252,0,0,1-.646.444l.931,1.308Zm-.321-1.8a.983.983,0,0,0,0-1.414,1.328,1.328,0,0,0-.866-.248H421.06V628.9h1.013A1.318,1.318,0,0,0,422.94,628.646Z" transform="translate(-420.657 -626.603)" fill="#fff"/>
      <path id="Path_63" data-name="Path 63" d="M475.509,630.1v.349h-2.7v-3.814h2.615v.349h-2.212v1.357h1.972v.343h-1.972V630.1Z" transform="translate(-464.35 -626.603)" fill="#fff"/>
      <path id="Path_64" data-name="Path 64" d="M521.5,630.177a1.5,1.5,0,0,1-.6-.359l.158-.311a1.507,1.507,0,0,0,.55.335,2.01,2.01,0,0,0,.708.128,1.294,1.294,0,0,0,.768-.188.579.579,0,0,0,.256-.488.488.488,0,0,0-.139-.365.94.94,0,0,0-.341-.21,5.5,5.5,0,0,0-.561-.161,5.9,5.9,0,0,1-.686-.21,1.127,1.127,0,0,1-.439-.308.818.818,0,0,1-.183-.561.952.952,0,0,1,.153-.526,1.045,1.045,0,0,1,.469-.379,1.92,1.92,0,0,1,.785-.142,2.314,2.314,0,0,1,.64.09,1.842,1.842,0,0,1,.542.248l-.136.321a1.883,1.883,0,0,0-.512-.237,1.914,1.914,0,0,0-.534-.079,1.233,1.233,0,0,0-.755.193.6.6,0,0,0-.253.5.5.5,0,0,0,.139.368.905.905,0,0,0,.349.213q.209.074.564.161a5.686,5.686,0,0,1,.678.2,1.115,1.115,0,0,1,.439.305.809.809,0,0,1,.18.553.939.939,0,0,1-.155.526,1.044,1.044,0,0,1-.477.376,1.99,1.99,0,0,1-.79.139A2.447,2.447,0,0,1,521.5,630.177Z" transform="translate(-504.637 -626.434)" fill="#fff"/>
      <path id="Path_65" data-name="Path 65" d="M574.867,626.636v3.814h-.4v-1.76h-2.387v1.76h-.4v-3.814h.4v1.7h2.387v-1.7Z" transform="translate(-547.176 -626.603)" fill="#fff"/>
      <path id="Path_66" data-name="Path 66" d="M626.1,629.431h-2.125l-.458,1.019H623.1l1.744-3.814h.4l1.744,3.814h-.425Zm-.147-.327-.915-2.049-.915,2.049Z" transform="translate(-590.249 -626.603)" fill="#fff"/>
      <path id="Path_67" data-name="Path 67" d="M681.382,626.985a1.314,1.314,0,0,1,0,1.915,1.732,1.732,0,0,1-1.144.346h-1.024v1.2h-.4v-3.814h1.427A1.724,1.724,0,0,1,681.382,626.985Zm-.289,1.659a.858.858,0,0,0,.3-.7.87.87,0,0,0-.3-.711,1.329,1.329,0,0,0-.866-.248h-1.013v1.907h1.013A1.329,1.329,0,0,0,681.093,628.644Z" transform="translate(-636.923 -626.603)" fill="#fff"/>
      <path id="Path_68" data-name="Path 68" d="M730.46,626.636h.4v3.814h-.4Z" transform="translate(-680.193 -626.603)" fill="#fff"/>
      <path id="Path_69" data-name="Path 69" d="M771.664,626.636v3.814h-.333l-2.452-3.1v3.1h-.4v-3.814h.332l2.457,3.095v-3.095Z" transform="translate(-712.041 -626.603)" fill="#fff"/>
      <path id="Path_70" data-name="Path 70" d="M824.595,628.374h.387v1.455a1.864,1.864,0,0,1-.632.36,2.35,2.35,0,0,1-.768.125,2.107,2.107,0,0,1-1.03-.251,1.849,1.849,0,0,1-.719-.695,2.022,2.022,0,0,1,0-1.989,1.85,1.85,0,0,1,.719-.695,2.122,2.122,0,0,1,1.035-.251,2.231,2.231,0,0,1,.8.139,1.592,1.592,0,0,1,.616.411l-.251.256a1.567,1.567,0,0,0-1.15-.447,1.713,1.713,0,0,0-.836.2,1.5,1.5,0,0,0-.586.567,1.573,1.573,0,0,0-.213.809,1.556,1.556,0,0,0,.213.806,1.534,1.534,0,0,0,.583.567,1.679,1.679,0,0,0,.834.207,1.661,1.661,0,0,0,1-.294Z" transform="translate(-756.521 -626.434)" fill="#fff"/>
      <path id="Path_71" data-name="Path 71" d="M914.158,626.636h1.553a2.269,2.269,0,0,1,1.068.242,1.769,1.769,0,0,1,.719.676,2.038,2.038,0,0,1,0,1.978,1.771,1.771,0,0,1-.719.676,2.271,2.271,0,0,1-1.068.242h-1.553Zm1.531,3.465a1.847,1.847,0,0,0,.877-.2,1.444,1.444,0,0,0,.583-.55,1.684,1.684,0,0,0,0-1.618,1.445,1.445,0,0,0-.583-.55,1.851,1.851,0,0,0-.877-.2h-1.128V630.1Z" transform="translate(-834.085 -626.603)" fill="#fff"/>
      <path id="Path_72" data-name="Path 72" d="M969.771,626.636h.4v3.814h-.4Z" transform="translate(-880.674 -626.603)" fill="#fff"/>
      <path id="Path_73" data-name="Path 73" d="M1008.729,628.374h.387v1.455a1.865,1.865,0,0,1-.632.36,2.351,2.351,0,0,1-.768.125,2.107,2.107,0,0,1-1.03-.251,1.848,1.848,0,0,1-.719-.695,2.02,2.02,0,0,1,0-1.989,1.849,1.849,0,0,1,.719-.695,2.122,2.122,0,0,1,1.035-.251,2.234,2.234,0,0,1,.8.139,1.594,1.594,0,0,1,.616.411l-.251.256a1.568,1.568,0,0,0-1.15-.447,1.714,1.714,0,0,0-.837.2,1.5,1.5,0,0,0-.586.567,1.573,1.573,0,0,0-.213.809,1.557,1.557,0,0,0,.213.806,1.532,1.532,0,0,0,.583.567,1.68,1.68,0,0,0,.834.207,1.661,1.661,0,0,0,1-.294Z" transform="translate(-910.778 -626.434)" fill="#fff"/>
      <path id="Path_74" data-name="Path 74" d="M1061.622,626.636h.4v3.814h-.4Z" transform="translate(-957.622 -626.603)" fill="#fff"/>
      <path id="Path_75" data-name="Path 75" d="M1097.284,626.985h-1.34v-.349h3.084v.349h-1.34v3.466h-.4Z" transform="translate(-986.375 -626.603)" fill="#fff"/>
      <path id="Path_76" data-name="Path 76" d="M1144.855,629.431h-2.125l-.458,1.019h-.419l1.743-3.814h.4l1.743,3.814h-.425Zm-.147-.327-.915-2.049-.916,2.049Z" transform="translate(-1024.835 -626.603)" fill="#fff"/>
      <path id="Path_77" data-name="Path 77" d="M1197.566,626.636h.4V630.1h2.136v.349h-2.539Z" transform="translate(-1071.509 -626.603)" fill="#fff"/>
      <path id="Path_78" data-name="Path 78" d="M1280.244,630.177a1.5,1.5,0,0,1-.6-.359l.158-.311a1.511,1.511,0,0,0,.55.335,2.011,2.011,0,0,0,.709.128,1.294,1.294,0,0,0,.768-.188.579.579,0,0,0,.256-.488.488.488,0,0,0-.139-.365.941.941,0,0,0-.341-.21,5.5,5.5,0,0,0-.561-.161,5.908,5.908,0,0,1-.686-.21,1.13,1.13,0,0,1-.439-.308.818.818,0,0,1-.182-.561.951.951,0,0,1,.152-.526,1.046,1.046,0,0,1,.469-.379,1.92,1.92,0,0,1,.785-.142,2.313,2.313,0,0,1,.64.09,1.841,1.841,0,0,1,.542.248l-.136.321a1.88,1.88,0,0,0-.512-.237,1.915,1.915,0,0,0-.534-.079,1.234,1.234,0,0,0-.755.193.6.6,0,0,0-.253.5.5.5,0,0,0,.139.368.909.909,0,0,0,.349.213q.21.074.564.161a5.666,5.666,0,0,1,.678.2,1.112,1.112,0,0,1,.439.305.807.807,0,0,1,.18.553.938.938,0,0,1-.155.526,1.043,1.043,0,0,1-.477.376,1.99,1.99,0,0,1-.79.139A2.447,2.447,0,0,1,1280.244,630.177Z" transform="translate(-1140.267 -626.434)" fill="#fff"/>
      <path id="Path_79" data-name="Path 79" d="M1332.993,626.985a1.314,1.314,0,0,1,0,1.915,1.731,1.731,0,0,1-1.144.346h-1.024v1.2h-.4v-3.814h1.428A1.724,1.724,0,0,1,1332.993,626.985Zm-.289,1.659a.858.858,0,0,0,.3-.7.871.871,0,0,0-.3-.711,1.329,1.329,0,0,0-.866-.248h-1.014v1.907h1.014A1.329,1.329,0,0,0,1332.7,628.644Z" transform="translate(-1182.806 -626.603)" fill="#fff"/>
      <path id="Path_80" data-name="Path 80" d="M1380.138,629.431h-2.125l-.458,1.019h-.419l1.743-3.814h.4l1.744,3.814h-.425Zm-.147-.327-.915-2.049-.916,2.049Z" transform="translate(-1221.942 -626.603)" fill="#fff"/>
      <path id="Path_81" data-name="Path 81" d="M1431.4,630.063a1.831,1.831,0,0,1-.714-.695,2.038,2.038,0,0,1,0-1.989,1.829,1.829,0,0,1,.717-.695,2.1,2.1,0,0,1,1.024-.251,2.127,2.127,0,0,1,.785.142,1.635,1.635,0,0,1,.61.414l-.257.256a1.518,1.518,0,0,0-1.128-.452,1.656,1.656,0,0,0-.823.207,1.516,1.516,0,0,0-.581.567,1.655,1.655,0,0,0,0,1.613,1.515,1.515,0,0,0,.581.567,1.655,1.655,0,0,0,.823.207,1.5,1.5,0,0,0,1.128-.458l.257.256a1.652,1.652,0,0,1-.613.417,2.1,2.1,0,0,1-.787.144A2.078,2.078,0,0,1,1431.4,630.063Z" transform="translate(-1266.59 -626.434)" fill="#fff"/>
      <path id="Path_82" data-name="Path 82" d="M1487.231,630.1v.349h-2.7v-3.814h2.615v.349h-2.212v1.357h1.973v.343h-1.973V630.1Z" transform="translate(-1311.915 -626.603)" fill="#fff"/>
    </g>
    <path id="Path_83" data-name="Path 83" d="M408.152,429.917h15.353v5H408.152Zm0,14.647h15.353v-5H408.152Zm0,9.386h15.353v-5H408.152Zm57.57-24.033v5h15.353v-5Zm0,14.647h15.353v-5H465.722Zm0,9.386h15.353v-5H465.722Zm-92.627,0h4.969V429.779H373.1Zm-103.876-23.6H251v5h18.219Zm-11.594,23.6h4.969V438.225h-4.969Zm19.451-23.6-9.109,23.6h5.8l6.073-16.382,6.073,16.382h6.073l-9.385-23.6Zm16.177,11.107c0,11.662,12.008,12.495,12.008,12.495V448.4s-6.763-.278-6.625-6.942,6.625-6.525,6.625-6.525v-5.275S293.253,429.794,293.253,441.456Zm43.57.417s-.276,6.8-6.9,6.664-6.487-6.664-6.487-6.664H323.4V429.655h-5.21v12.217s.138,12.078,11.732,12.078,12.422-12.078,12.422-12.078V440.9h-5.521Zm25.917,3.193-11.594-15.271h-5.521V440.9h5.245v-2.36l11.87,15.41h4.969V429.794h-4.969Zm30.155,1.388-6.487-16.521h-5.521l8.971,24.018h5.8l9.109-24.018h-5.521Zm48.875-7.219a9.265,9.265,0,0,0-7.039-9.3h-7.039v5h5.935a3.384,3.384,0,0,1,2.9,3.332c0,2.915-3.036,3.679-3.036,3.679h-5.8v5.206l6.9,6.8h7.177l-7.039-6.942S441.769,445.9,441.769,439.234Zm12.946.018-2.978,0a1.729,1.729,0,0,1-1.844-1.767,1.572,1.572,0,0,1,1.772-1.634l1.542.032v-5.612l-1.528.017c-2.595.029-7.5,1.563-7.5,7.165,0,5.55,4.863,7.286,7.435,7.428l2.978,0a1.729,1.729,0,0,1,1.844,1.767,1.57,1.57,0,0,1-1.76,1.634h-.012l-1.542-.032v5.612l1.528-.017c2.595-.029,7.5-1.563,7.5-7.165C462.15,441.13,457.287,439.395,454.715,439.252Z" transform="translate(0 0)" fill="url(#linear-gradient)"/>
  </g>
</svg>
    </div>
  )
}