import React from 'react'
import Mailchimp from 'react-mailchimp-form'
import styles from './assets/subscribeForm.module.sass'
import { Container } from "@material-ui/core"


function SubscribeForm() {
    return (
        <div 
            className={styles.subscribeFormSection}>
            <Container>
                <Mailchimp
                    action="https://theappconcept.us4.list-manage.com/subscribe/post?u=a453a477846568ecf0291759e&amp;id=a40b52bced"
                    fields={[
                        {
                            name: 'EMAIL',
                            placeholder: 'Subscribe to our newsletter...',
                            type: 'email',
                            required: true
                        }
                    ]}
                    messages={{
                        empty: "You must write an e-mail.",
                        success: "Thank you for subscribing!",
                        error: "An unexpected internal error has occurred.",
                        button: "Subscribe"
                    }}
                    className={styles.subscribeForm}
                />
            </Container>
        </div>
    )
}

export default SubscribeForm