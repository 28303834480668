import React from 'react'
import styles from './mobileApp.module.sass'
import { Container,Grid } from '@material-ui/core';
import bg from '../../../imgs/dove-deloping-mobile-app-mobile-img-min.jpeg';
import bgTwo from "../../../imgs/seven-day-challange-mobile-img-min.jpeg";
import bgThree from "../../../imgs/dove-rewarding-the-ones-mobile-img-min.jpeg";
import bgFour from "../../../imgs/dove-by-the-end-of-technotest-mobile-img-min.jpeg";
const MobileApp = () => {
    return(
      <div className={styles.mobileAppContainer}>
          <div className={styles.mobileApp}>
         <Container>
                <Grid container justify="center">
                    <Grid item xs={12} style={{textAlign:"center"}}>
                        <h2 className={styles.title}> Mobile App </h2>
                    </Grid>
                    <Grid item xs={12}>
<p className={styles.paragraph}>Developing a mobile app for real women.</p>
                    </Grid>
                </Grid>
            </Container>
            </div>

            <div className={`${styles.bg} ${styles.first}`}>
                <Grid container>
                    <Grid item md={7} xs={12}>
                        <h3 className={styles.developing}>Developing a mobile app <br/>
                        for real women
                        </h3>
                        <p className={styles.developingContent}>We rose to the challenge by crafting a leading-edge App that is easy to use, interactive, and available for everybody just a tap away.</p>
                    </Grid>
                    <Grid item xs={12}>
                    <div className={styles.bgMobile}><img src={bg} alt=""/></div>
                    </Grid>
            </Grid>
            </div>
            <div className={`${styles.bg} ${styles.second}`}>
                <Grid container>
                    <Grid item md={7} xs={12}>
                        <h3 className={styles.developing}>A 7-day challenge<br/>
                        in your pocket                        </h3>
                        <p className={styles.developingContent}>In order to get real women excited about Dove’s new shampoo formula and the challenge to reconstruct women’s hair in just 7 days, our goal was to provide them with a constantly changing daily dose of inspiration.</p>
                    </Grid>
                    <Grid item xs={12}>
                    <div className={styles.bgMobile}><img src={bgTwo} alt=""/></div>
                    </Grid>
            </Grid>
            </div>
            <div className={`${styles.bg} ${styles.third}`}>
                <Grid container>
                    <Grid item md={5} xs={12}>
                        <h3 className={styles.developing}>Rewarding the ones<br/>
                        who take it to the top                      </h3>
                        <p className={styles.developingContent}>To reward users who were able to successfully complete the challenges and activities they got in their list each day, we established a leader board. For each activity checked off the list, users gain a certain amount of points, and climb their way up the board.</p>
                    </Grid>
                    <Grid item xs={12}>
                    <div className={styles.bgMobile}><img src={bgThree} alt=""/></div>
                    </Grid>
            </Grid>
            </div>
            <div className={`${styles.bg} ${styles.fourth}`}>
                <Grid container justify="flex-end">
                    <Grid item md={6} xs={12}>
                        <h3 className={styles.developing} style={{textAlign:"center",fontSize:"35px"}}>By the end of the contest,<br/>
                        10 lucky women won a luxurious <br/> free day pass to the four seasons hotel spa.                 </h3>
                    </Grid>
                    <Grid item xs={12}>
                    <div className={styles.bgMobile}><img src={bgFour} alt=""/></div>
                    </Grid>
            </Grid>
            </div>
            <div className={styles.mobileApp} style={{paddingTop:"150px"}}></div>
            </div>
    )
}
export default MobileApp;