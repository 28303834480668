import React, { useRef } from "react";
import FirstSection from "./fisrtSectionAbout/firstSectionAbout";
import HumbleBeginnings from "./humbleBeginnings/humbleBeginnings";
import TheTeam from "./theTeam/theTeam";
import DownloadOurProfile from "./downloadOurProfile/downloadOurProfile";
import SubscribeForm from "../subscribeForm/subscribeForm";
import ContactSection from "../home/contactSection/contactSection";
import { AppState } from "../../redux/reducers";
import { useSelector } from "react-redux";
import { IAboutSection } from "../../redux/types";

const About = () => {
	const sectionContainer = useRef<HTMLDivElement>(null);
	const aboutData: IAboutSection[] = useSelector((state: AppState) => state.aboutData);

	return (
		<div ref={sectionContainer}>
			<FirstSection/>
			<HumbleBeginnings content={aboutData[1]} />
			<TheTeam content={aboutData[2]}/>
			<DownloadOurProfile />
			<ContactSection
				// sectionNumber="006"
				// sectionSubTitle="CONTACT US"
				quote="Ready To Get Started With TAC?"
				link={{ linkText: "GET A QUOTE", linkUrl: "" }}
			/>
			<SubscribeForm/>
		</div>
	);
};
export default About;
