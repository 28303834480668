import React from "react";
import { Container,Grid } from '@material-ui/core';
import styles from './creativeDevelopment.module.sass';
import bgOne from '../../../imgs/dove-challange-mobile-min.jpeg';


const CreativeDevelopment=()=>{
    return(
        <div className={styles.creativeDevelopmentContainer}>
            <Container>
                <Grid container justify="center">
                    <Grid item xs={12} style={{textAlign:"center"}}>
                        <h2 className={styles.title}>Creative & Content Development</h2>
                    </Grid>
                </Grid>
            </Container>
            <div className={`${styles.bg} ${styles.first}`}>
                <Grid container>
                    <Grid item md={7} xs={12}>
                        <h3 className={styles.challenge}>Challenge</h3>
                        <p className={styles.challengeContent}>As a global anchor in the beauty & personal care market, Dove has always postioned itself as a brand that genuinely cares about what real women are all about. Our main challenge was to promote Dove’s Intensive Repair by creating</p>
                        <h3 className={`${styles.challenge} ${styles.center}`}>“The 7 Days Challenge”</h3>
                        <p className={`${styles.challengeContent} ${styles.center}`}>An integrated online campaign aligned with the brand’s image…</p>
                    </Grid>

            </Grid>
            </div>
            <div className={styles.bgMobile}><img src={bgOne} alt=""/></div>

            <div className={`${styles.bg} ${styles.second}`}>
                <Grid container>
                    <Grid item md={7} xs={12}>
                        <h3 className={styles.challenge}>Empowering women to move forward</h3>
                        <p className={styles.challengeContent}>As Dove upgraded the formula of its shampoos, it turned to TAC Universe to help create an online campaign and market its brand new formula for Real Women.</p>
                    </Grid>

            </Grid>
            </div>
        </div>
    )
}
export default CreativeDevelopment;