import React from 'react'
import styles from './dove.module.sass'
import { Container,Grid } from '@material-ui/core';
import DoveBanner from '../../imgs/dove-cover-banenr.jpeg';
import DoveBg from '../../imgs/new-formula-for-inspiration.jpeg';
import DoveBgTwo from '../../imgs/dove-rules-banner.jpeg';
import CreativeDevelopment from './creativeDevelopment/creativeDevelopment';
import MobileApp from './mobileApp/mobileApp';
import WebDevelopment from './webDevelopment/webDevelopment';
import CommunityManagement from './communityManagement/communityManagement';
import Result from './result/result';
const Dove = () => {
    return(
      <div className={styles.DoveContainer}>
        <img src={DoveBanner} alt=""/>
        <Container>
        <div className={styles.content}>
          <Grid container justify="center" spacing={3}>
        <Grid item xs={12}>
        <h2 className={styles.title}>DOVE</h2>
        </Grid>

        <Grid item xs={12}>
          <div className={styles.portfolioMeta}>
          <span className={styles.subTitle}>Client:</span>
          <a className={styles.link} href='/dove'>Dove</a>
          </div>
          <div className={styles.portfolioMeta}><span className={styles.subTitle}>Service:</span>
          <a className={styles.link} href='/dove'>Web Design</a></div>
        </Grid>
        <Grid item xs={12} style={{marginTop:"40px"}}>
          <p className={styles.paragraph}><strong>A brand’s success is not merely about selling products or services, it is about connecting people…</strong></p>
          <p className={styles.paragraph}>Here’s a story about using Digital Media to redefine challenge, and beauty as we know it.</p>
        </Grid>
        </Grid>
        </div>
        </Container>
        <div>
          <img src={DoveBg} alt=""/>
        </div>
        <div>
          <img src={DoveBgTwo} alt=""/>
        </div>
        {/* <Container> */}
          {/* <Grid container>
            <Grid item xs={12}> */}
              <CreativeDevelopment/>
              <MobileApp/>
              <WebDevelopment/>
              <CommunityManagement/>
              <Result/>
            {/* </Grid>
          </Grid>
        </Container> */}
       </div>
    )
}
export default Dove;