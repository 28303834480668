import React from "react";
import styles from "./newInner.module.sass";
import { faFacebookF, faPinterestP, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faLongArrowAltRight} from '@fortawesome/free-solid-svg-icons'
import {Container } from "@material-ui/core";
import { useHistory} from 'react-router-dom';
import Greeting from '../../../imgs/greeting-option.png';
import Almaza from '../../../imgs/almaza-eid.png';
import EidPost from '../../../imgs/eid-post.png';

const EidInner = () => {
	let history = useHistory();

const handleBackIcon=()=>{
    history.push("/news");

}

	return (
		<div className={styles.newsInnerContainer}>
			<Container>
									<FontAwesomeIcon icon={faLongArrowAltRight} className={styles.backIconForNews} 
									onClick={handleBackIcon}
									 />
			{/* <TrendingFlat className={styles.backIconForNews} onClick={props.handleBackIcon} /> */}
			<h2>Greetings, The Egyptian Way!</h2>
			<span>
				{/* <i>article</i> */}
			</span>
			<div className={styles.typeAndDateContainer}>
				<h3>Articles</h3>
				<div>
					<a href="/" target="_blank" rel="noreferrer">
						{/* <FacebookIcon /> */}
						<FontAwesomeIcon icon={faFacebookF} />

					</a>
					<a href="/" target="_blank" rel="noreferrer">
						{/* <PinterestIcon /> */}
						<FontAwesomeIcon icon={faPinterestP} />

					</a>
					<a href="/" target="_blank" rel="noreferrer">
						{/* <LinkedInIcon /> */}
						<FontAwesomeIcon icon={faLinkedinIn} />

					</a>
					<a href="/" target="_blank" rel="noreferrer">
						{/* <TwitterIcon /> */}
						<FontAwesomeIcon icon={faTwitter} />

					</a>
				</div>
			</div>
            <div>
				<img src={EidPost} alt="" />
			</div>
            <p>With each occasion coming up, brainstorming rooms are on fire with every creative mind in the field trying to come up with the perfect greetings idea that goes with the celebrated occasion along with serving its brand.
While ideas vary and could be presented in many ways, the Egyptian mind always finds a suitable way to celebrate with its audience.</p>
<p>Shall we take a look?</p>
<h3>Sultan The Penguin, Ski Egypt - Majid Al Futtaim</h3>
<iframe style={{width:"100%"}}  height="500" src="https://www.youtube.com/embed/cIpszuz04r0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
<h3>Dental Arts</h3>
<img style={{width:"100%"}} src={Greeting} alt="" />
<h3>Labanita - Mansour Group</h3>
<iframe style={{width:"100%"}}  height="500" src="https://www.youtube.com/embed/it72WtYXLvU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>  	
<h3>Hayat - Mansour Group</h3>	
<iframe style={{width:"100%"}} height="500" src="https://www.youtube.com/embed/Q5cSJR6J6PE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
<h3>Maison Popcorn - Multifood</h3>
<iframe style={{width:"100%"}}  height="500" src="https://www.youtube.com/embed/hcFD5nCXifk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
<h3>City Centre Almaza - Majid Al Futtaim</h3>
<img style={{width:"100%"}} src={Almaza} alt="" />
    <p>TAGS: <span><i></i></span></p>
			<h3>SHARE ARTICLE:</h3>
			<a href="/">
						{/* <FacebookIcon /> */}
						<FontAwesomeIcon icon={faFacebookF} />
					</a>
					<a href="/">
						{/* <PinterestIcon /> */}
						<FontAwesomeIcon icon={faPinterestP} />
					</a>
					<a href="/">
						{/* <LinkedInIcon /> */}
						<FontAwesomeIcon icon={faLinkedinIn} />

					</a>
					<a href="/">
						{/* <TwitterIcon /> */}
						<FontAwesomeIcon icon={faTwitter} />

					</a>
					</Container>
		</div>
	);
};
export default EidInner;
