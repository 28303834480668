import React, { Fragment } from "react";
import { Container, Grid } from "@material-ui/core";
import styles from "./secondSection.module.sass";
import play from "../../../imgs/play-icon.svg";
import { IHomeSection } from "../../../redux/types";
import { Link } from "react-router-dom";

interface ISecondSection {
	content: IHomeSection;
	textColor: string;
}

function SecondSection(props: ISecondSection) {
	return (
		<div
		    id='aboutUs'
			className={styles.sectionContainer}
			data-color={props.textColor??'#fff'}
			style={{
				backgroundImage: `url(${process.env.REACT_APP_BASE_URL}${props.content.section_content.BG?.url})`,
			}}
		>
			<Container className={styles.section}>
				<Grid
					container
					justify="space-between"
					alignItems="center"
					className={styles.contentContainer}
				>
					<Grid item xs={12} md={3}>
						<p>
							<span>{props.content.section_content.section_number}</span>{" "}
							{props.content.section_content.sub_title}
						</p>
						<h2>{props.content.section_content.title}</h2>
						{!props.content.section_content.move_content_right && (
							<Fragment>
								{props.content.paragraphs.map((paragraph) => {
									return (
										<p key={paragraph.id} className={styles[paragraph.style_text]}>
											{paragraph.paragraph}
										</p>
									);
								})}
								<div>
									<button>{props.content.section_content.button[0].button_text}</button>
									<button>
										<img src={play} alt="play video" />
									</button>
								</div>
							</Fragment>
						)}
					</Grid>
					<Grid item xs={12} md={7}>
						<Grid container>
							 <Grid item xs={12} md={11}>
								{props.content.section_content.move_content_right && (
									<>
									{props.content.paragraphs.map((paragraph) => {
											return (
												<p key={paragraph.id} className={styles[paragraph.style_text]}>
													{paragraph.paragraph}
												</p>
											);
										})}
										<div>
										<button>
										<a href="https://we.tl/t-QUnCNcvfIo" target="_blank" rel="noreferrer">
											{props.content.section_content.button[1].button_text}</a>															
										 </button>
										 <button><Link to="/about">{props.content.section_content.button[0].button_text}</Link></button>
										 </div>
									</>
								 )} 
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}

export default SecondSection;
