import React, { useEffect, useRef } from "react";
import Slider,{Settings} from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./assets/sectionWithSlider.module.sass";
import { Grid, Container } from "@material-ui/core";
import { ISliders, IParagraphs } from "../../redux/types";
import { RequestedImage } from "../../redux/types/defaultTypes";
import {Link,useHistory } from "react-router-dom";

interface ISectionWithSlider {
	slides: ISliders[];
	settings: Settings;
	sectionNumber: string;
	sectionTitle: string;
	sectionSubTitle: string;
	sectionParagraphs?: IParagraphs[];
	BGColor: string;
	classes?: string[];
	button?: {
		button_text: string;
		button_url?: string;
	};
	textColor: string | undefined;
	// click: ()=>void
}


function SectionWithSlider(props: ISectionWithSlider) {
	const history = useHistory();

	const click=(title:string|undefined)=>{
		if (title === "DOVE") {
			history.push("/dove");
		}
		else if (title === "CAIROKEE") {
			history.push("/cairokee");
		}
		else if (title === "DOMINO'S") {
			history.push("/dominos");
		}
		else if (title?.includes("THE E-COMMERCE")) {
			history.push(`/new/${title.replace(/[^a-zA-Z ]/g, "").replace(/\s/g,"-")}`);
		}
		else if (title?.includes("7 STEPS")) {
			history.push(`/new/${title.replace(/[^a-zA-Z ]/g, "").replace(/\s/g,"-")}`);
		
		}
		else if (title?.includes("CHEAT SHEET")) {
			history.push(`/new/${title.replace(/[^a-zA-Z ]/g, "").replace(/\s/g,"-")}`);
		}
		else{
			history.push("/");
		}
		
	}
	const slider = useRef<Slider>(null);
	useEffect(() => {
		if (slider.current) {
		}
	}, [props, slider]);
	return (
		<div
			data-color={props.textColor??'#000'}
			className={`${props.classes?.join(" ")} ${styles.sectionContainer}`}
			style={{ backgroundColor: props.BGColor }}
		>
			<Container>
				<Grid container alignItems="center">
					<Grid item xs={12} md={12}>
						<p className={styles.sectionName}>
							<span>{props.sectionNumber}</span> {props.sectionSubTitle}
						</p>
						<Grid container justify="space-between">
							<Grid item xs={12} md={7}>
								<h2>{props.sectionTitle}</h2>
								{props.sectionParagraphs?.map((paragraph) => (
									<p key={paragraph.id}>{paragraph.paragraph}</p>
								))}
							</Grid>
							<Grid item xs={12} md={3}>
								{props.button ? <Link to={props.button.button_url?props.button.button_url:""}><button>{props.button.button_text}</button></Link>  : null}
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Slider ref={slider} {...props.settings} className={styles.sliderContainer}>
							{props.slides.map((slide: ISliders) => {
								return (
									<div key={slide.id} className={styles.slide} onClick={()=>click(slide.title)}>
										<div>
											{slide.slider_images.map((image: RequestedImage) => {
												return (
													<div key={image.id}>
														<img src={`${process.env.REACT_APP_BASE_URL}${image.url}`} alt="slider img" />
													</div>
												);
											})}
										</div>
										{slide.title ? <h2>{slide.title}</h2> : null}
										{slide.paragraph ? <p>{slide.paragraph}</p> : null}
									</div>
								);
							})}
						</Slider>
					</Grid>
				
				</Grid>
			</Container>
		</div>
	);
}

export default SectionWithSlider;
