import { Container, Grid } from '@material-ui/core';
import React from 'react';
import { IsubSlider } from '../../../redux/types';
import styles from './innerClickedSection.module.sass';
import { Link } from "react-router-dom";



const BrandRefresh=(props:IsubSlider,ref:any)=>{

    return(
        <div className={styles.brandRefreshContainer} ref={ref}>
            <Container>
                <Grid container justify="space-between">
                <Grid item md={5} xs={12}>
    <h3>{props.sub_title}</h3>
            <p> {props.paragraph}<br/> <br/>
</p>
    <i>{props.span}</i>
  <div className={styles.speackWithOurStrategist} >
    <Link to="/contactUs"><h3>SPEAK WITH OUR STRATEGIST</h3> </Link>
        </div>
            </Grid>
            <Grid item md={7} xs={12}>
         <img src={`${process.env.REACT_APP_BASE_URL}${props.img.url}`} alt=""/>
            </Grid>
          </Grid>
          </Container>
        </div>
    )
}
const forwaredInnerClickedSection=React.forwardRef(BrandRefresh)
export default forwaredInnerClickedSection;
// export default BrandRefresh;