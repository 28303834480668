import axios from "../../axios";
import { EReduxActionTypes } from "../types/defaultTypes";
import { IHomeSection } from "../types/index";
import { Dispatch } from "redux";

function HomeData(homeData: IHomeSection[]) {
	return {
		type: EReduxActionTypes.INITIAL_DATA,
		homeData,
	};
}

export function getHomeData() {
	return (dispatch: Dispatch) => {
		return new Promise<void>((resolve, reject) => {
			axios.get("/home-sections").then((response) => {
				// console.log(response);
				let data = response.data;
				dispatch(HomeData(data));
				resolve();
			});
		});
	};
}
