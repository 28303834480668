import React from "react";
import FirstSection from "./firstSection/firstSection";
import CaseStudies from "./caseStudies/caseStudies";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/reducers";
import { ICaseStudySection } from "../../redux/types";
import SubscribeForm from "../subscribeForm/subscribeForm";
import ContactSection from "../home/contactSection/contactSection";

const Work = () => {
	// const sectionContainer = useRef<HTMLDivElement>(null);
	// useEffect(() => {
	// 	let callback = (
  //     entries: IntersectionObserverEntry[],
  //     observer: object
  //   ): void => {
	// 		entries.forEach((entry) => {
	// 			// console.log(entry)
  //       if (entry.intersectionRatio >= 0.4) {
  //         if (entry.target instanceof HTMLDivElement) {
  //           let textColor = entry.target.dataset.color ?? "#000"; 
	// 					updateHeaderColor(textColor);
	// 					// console.log(entry.target)
  //         }
  //       }
  //     });
  //   };
  //   let observer = new IntersectionObserver(callback, {
  //     threshold: [0.4],
  //   });
  //   let secondSections: Array<Element>;
  //   if (
  //     sectionContainer &&
  //     sectionContainer.current &&
  //     sectionContainer.current.children
  //   ) {
  //    secondSections = Array.from(sectionContainer.current.children);
  //     secondSections.forEach((section) => {
	// 			observer.observe(section);
	// 			// console.log(section)
  //     });
  //   }
	// },[updateHeaderColor,sectionContainer])

	const caseStudyData: ICaseStudySection[] = useSelector((state: AppState) => state.caseStudyData);

	return (
		<div>
			<FirstSection textColor='#000' content={caseStudyData[0]} />
      <CaseStudies textColor='#fff' content={caseStudyData[0]} />
      <ContactSection
				// sectionSubTitle="CONTACT US"
				quote="Ready To Get Started With TAC?"
				link={{ linkText: "GET A QUOTE", linkUrl: "" }}
			/>
      <SubscribeForm/>

		</div>
	);
};
export default Work;
