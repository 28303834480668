import React, { useState, useRef, useEffect } from "react";
import styles from "./assets/header.module.sass";
import { Link, useLocation } from "react-router-dom";
// import logo from "../../imgs/full-logo.svg";
import Nav from "./nav/nav";
import Logo from "./assets/headerLogo";
// import { Container, Grid } from "@material-ui/core";

interface IHeader {
	fixedHeaderPaths: string[];
	textColor?: string | undefined;
	widthAndLeft:{w:number,x:number}
}

function Header({ fixedHeaderPaths,widthAndLeft }: IHeader) {
	const { pathname } = useLocation();
	const header = useRef<HTMLDivElement>(null);
	const spanOne = useRef<HTMLSpanElement>(null);
	const spanTwo = useRef<HTMLSpanElement>(null);
	const spanThree = useRef<HTMLSpanElement>(null);
	// const [fixedHeader,setFixedHeader]=useState<boolean>(false);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	function handleMenu() {
		setIsOpen(!isOpen);
	}
useEffect(()=>{
	// if (header.current) {
	// setStyle({
	// 	':after': 
	// 	  {width: widthAndLeft.w +"px"  ,
	// 	  left: widthAndLeft.x +"px"}
	//   });
	if (header.current) {
	// 	setStyle(<style>
    //         {`:after { width: ${widthAndLeft.w} px  ;
	// 		  left: ${widthAndLeft.x} px}`
	// }</style>
			
	// 	  );
	//   console.log(widthAndLeft.w)
}},[widthAndLeft])
// console.log(style)
// 	useEffect(() => {
// 		if (header.current) {
// 			window.addEventListener(
// 				"scroll",
// 				() => {
// 					if (header.current&&spanOne.current&&spanThree.current&&spanTwo.current) {
// 						if (window.scrollY >= 200) {
// 							header.current.style.cssText = "background-color: #000;color: #fff";
// 							spanOne.current.style.cssText = "background-color: #fff ;border-color: #fff";
// 							spanTwo.current.style.cssText = "background-color: #fff ;border-color: #fff";
// 							spanThree.current.style.cssText = "background-color: #fff ;border-color: #fff";
// setFixedHeader(true)
// 						} else {
// 							header.current.style.cssText = "";
// 							spanOne.current.style.cssText = "";
// 							spanTwo.current.style.cssText = "";
// 							spanThree.current.style.cssText = "";
// 							setFixedHeader(false);

// 						}
// 					}
// 				},
// 				{ passive: true }
// 			);
// 		}
// 	}, [header]);
	return (
		<header className={`${styles.header} ${fixedHeaderPaths.includes(pathname) ? styles.fixed : ""} head`} ref={header}>
			<style>
            {`.head:after { width: ${widthAndLeft.w}px  ;
			  left: ${widthAndLeft.x}px}`
	}</style>
			<div>
				<Link to="/">
					<Logo/>
					{/* <img src={logo} alt="Company Logo" /> */}
				</Link>
			</div>
			<div>
				<span><a href="tel:+20-122-555-9843">+20-122-555-9843</a></span>
				<button  onClick={handleMenu} className={isOpen ? styles.active : ""}>
					<span ref={spanOne} style={isOpen ?{backgroundColor:'#fff',borderColor: '#fff'}: {}} ></span>
					<span ref={spanTwo} style={isOpen ?{backgroundColor: '#000', borderColor: '#000'}: {}}></span>
					<span ref={spanThree} style={isOpen ?{backgroundColor:'#fff',borderColor: '#fff'}: {}}></span>
				</button>
				<Nav  setIsOpen={setIsOpen} isOpen={isOpen} />
			</div>
		</header>
	);
}

export default Header;
