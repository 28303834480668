import React from "react";
import styles from "./sliderItem.module.sass";
interface itemProps {
	heading: string;
	clicked: any;
	id:string|undefined;

}
const SliderItem = (props: itemProps) => {
	return (
	
		<div  className={styles.sliderItemContainer} id={props.id} onClick={props.clicked}>
			<h3>{props.heading}</h3>
		</div>

	);
};
export default SliderItem;
