import { EReduxActionTypes, IReduxBaseAction } from '../types/defaultTypes'
import { INewsSection } from '../types'

interface INewsData extends IReduxBaseAction {
    newsData: INewsSection | INewsSection[]
}

export default function newsData(state = [], action: INewsData) {
    switch (action.type) {
        case EReduxActionTypes.NEWS_DATA:
            return action.newsData
        default:
            return state
    }
}