import React, { useEffect, useRef, Dispatch, SetStateAction } from "react";
import styles from "./nav.module.sass";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFacebookF,
	faBehance,
	faPinterestP,
	faLinkedinIn,
	faTwitter,
	faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import coffee from "../../../imgs/coffee.png";
// import about from "../../about/about";

interface INav {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	// textColor: string|undefined;

}
// interface IColor{

// }
function Nav(props: INav) {
	const menu = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (menu.current && props.isOpen) {
			menu.current.style.cssText = "opacity: 1; visibility: visible";
			document.body.style.overflow = "hidden";
		} else if (menu.current && !props.isOpen) {
			menu.current.style.cssText = "opacity: 0; visibility: hidden";
			document.body.style.overflow = "";
		}
	}, [menu, props.isOpen]);

	function closeMenu() {
		props.setIsOpen(false);
	}
	return (
		<div ref={menu} className={styles.menu}>
			<div>
				<div>
					<p>GRAB A COFFEE</p>
				<Link to="/contactUs" onClick={closeMenu}>	<h1>
						GET A<br /> QUOTE
					</h1></Link>
				</div>
				<Link to="/contactUs" onClick={closeMenu}><img src={coffee} alt="coffee" /></Link>
			</div>
			<nav>
				<ul>
					<li>
						<Link data-text="HOME" to="/" onClick={closeMenu}>
							HOME
						</Link>
					</li>
					<li>
						<Link data-text="ABOUT" to="/about" onClick={closeMenu}>
							ABOUT
						</Link>
					</li>
					{/* <li>
						<Link data-text="WORK" to="/work" >
							WORK
						</Link>
					</li> */}
					
					<li>
						<Link data-text="CASE STUDIES" to="/work" onClick={closeMenu}>
							CASE STUDIES
						</Link>
					</li>
					
					<li>
						<Link data-text="SERVICES" to="/services" onClick={closeMenu}>
							SERVICES
						</Link>
					</li>
					<li>
						<Link data-text="CONTACT US" to="/contactUs" onClick={closeMenu}>
							CONTACT US
						</Link>
					</li>
					<li>
						<Link data-text="BLOG" to="/news" onClick={closeMenu}>
						BLOG
						</Link>
					</li>
					<li>
						<Link data-text="CAREERS" to="/careers" onClick={closeMenu}>
							CAREERS
						</Link>
					</li>
				</ul>
				<ul>
					<li>
					<a href="https://www.facebook.com/theappconcept" rel="noreferrer" target="_blank">
							<FontAwesomeIcon icon={faFacebookF} />
						</a>
					</li>
					<li>
					<a href="https://www.behance.net/tacuniverse" rel="noreferrer" target="_blank">
							<FontAwesomeIcon icon={faBehance} />
						</a>
					</li>
					<li>
					<a href="https://www.pinterest.com/tacuniverse/" target="_blank" rel="noreferrer">
							<FontAwesomeIcon icon={faPinterestP} />
						</a>
					</li>
					<li>
					<a href="https://www.linkedin.com/company/tacuniverse/mycompany/" target="_blank" rel="noreferrer">
							<FontAwesomeIcon icon={faLinkedinIn} />
						</a>
					</li>
					<li>
					<a href="https://twitter.com/tacuniverse" target="_blank" rel="noreferrer">
							<FontAwesomeIcon icon={faTwitter} />
						</a>
					</li>
					<li>
					<a href="https://www.instagram.com/tac.universe/" target="_blank" rel="noreferrer">
							<FontAwesomeIcon icon={faInstagram} />
						</a>
					</li>
				</ul>
			</nav>
		</div>
	);
}

export default Nav;
