import axios from "../../axios";
import { EReduxActionTypes } from "../types/defaultTypes";
import { IDominosSections } from "../types/index";
import { Dispatch } from "redux";

function DominosData(dominosData: IDominosSections[]) {
	return {
		type: EReduxActionTypes.DOMINOS_DATA,
		dominosData,
	};
}

export function getDominosData() {
	return (dispatch: Dispatch) => {
		return new Promise<void>((resolve, reject) => {
			axios.get("/domino-s-sections").then((response) => {
				// console.log(response);
				let data = response.data;
				dispatch(DominosData(data));
				resolve();
			});
		});
	};
}
