import React from 'react';
// import Greeting from '../../../imgs/greeting-option.png';
import EidPost from '../../../imgs/eid-post.png';
import styles from './itemNews.module.sass';

const Eid =()=>{
return(
    <div>
        {/* <div>
				<img src={EidPost} alt="" />
			</div>
        <h2>Greetings, The Egyptian Way!</h2>
        <p>With each occasion coming up, brainstorming rooms are on fire with every creative mind in the field trying to come up with the perfect greetings idea that goes with the celebrated occasion along with serving its brand.
While ideas vary and could be presented in many ways, the Egyptian mind always finds a suitable way to celebrate with its audience.</p>
<p>Shall we take a look?</p>
<h3>Sultan The Penguin, Ski Egypt - Majid Al Futtaim</h3>
<iframe width="560" height="315" src="https://www.youtube.com/embed/WMYDRp352Fo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
<img src={Greeting} alt="" />
<iframe width="560" height="315" src="https://www.youtube.com/embed/0X28OdkeHgE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>    */}
<div 
        // onClick={props.clicked} 
        className={styles.itemNewsContainer} >
            <div className={styles.gridForItemNews}>
                <div className={styles.contentItemNewsContainer}>
            <div className={styles.titleForItemNewsContainer}>
            <h3 className={styles.leftTitleForItemNews}>Articles</h3>
            <h3 className={styles.rightTitleForItemNews}><i></i></h3>
            </div>
            <div className={styles.imgForItemNews}>
                <img src={EidPost} alt=""/>
            </div>
            <div>
            <h2 className={styles.titleForImgForItemNews}>Greetings, The Egyptian Way!</h2>
            <div className={styles.paragraphForImgForItemNews}>
                            <p>With each occasion coming up, brainstorming rooms are on fire with every creative mind in the field trying to come up with the perfect greetings idea that goes with the celebrated occasion along with serving its brand.
While ideas vary and could be presented in many ways, the Egyptian mind always finds a suitable way to celebrate with its audience.</p>

                        </div>
            </div>
            </div>
            </div>
        </div>
 </div>
)
}
export {Eid};