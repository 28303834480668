import React from "react";
import { Container,Grid } from '@material-ui/core';
import styles from './communityManagement.module.sass';
import community from '../../../imgs/cairokee-community-management-img.jpeg';

const CommunityManagement=()=>{
    return(
        <div className={styles.communityManagementContainer}>
            <Container>
                <Grid container justify="center" spacing={3}>
                    <Grid item xs={12} style={{textAlign:"center"}}>
                        <h2 className={styles.title}> Community management </h2>
                    </Grid>
                    <Grid item xs={5}>
                    <h3 className={styles.challenge}>Community management:<br/> BRANDING WITH A HUMAN TOUCH</h3>
                    <p className={styles.challengeContent}>We wanted to stream the new merchandise line and make it loud enough for everyone in the Middle East & North Africa (MENA) to hear. We utilized Facebook & Instagram by creating a series of posts teenage Cairokee fans, asking them about their first Cairokee concert, their favorite songs and lyrics.</p>
                    <p className={styles.challengeContent}>Aside from engaging fans, we aimed at informing everyone on social space how, when, and most importantly where, they can get anything available at the merchandise line.</p>
                    <h3 className={styles.challenge}>A store about people:<br/> turning fans into influencers</h3>
                    <p className={styles.challengeContent}>By using eloquence content and appealing visuals, our purpose was to make the merchandise line not only available for all fans, but also make them promote the products themselves. The band’s music has always been about people’s most important issues, and thats exactly why their store had to revolve around turning their fans and lovers into influencers.</p>
                    </Grid>
                    <Grid item xs={6}>
                        <img src={community} alt=""/>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
export default CommunityManagement;