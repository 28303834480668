import { EReduxActionTypes, IReduxBaseAction } from '../types/defaultTypes'
import { ICaseStudySection } from '../types'

interface ICaseStudyData extends IReduxBaseAction {
    caseStudyData: ICaseStudySection | ICaseStudySection[]
}

export default function caseStudyData(state = [], action: ICaseStudyData) {
    switch (action.type) {
        case EReduxActionTypes.CASESTUDY_DATA:
            return action.caseStudyData
        default:
            return state
    }
}