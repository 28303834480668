import React from 'react'
import styles from './dominosSecondSection.module.sass'
import { IDominosSections } from '../../../redux/types';
import { Container, Grid } from '@material-ui/core';

interface ISecondSection {
  content: IDominosSections;
}

const DominosSecondSection = (props:ISecondSection) => {
    return(
      <div className={styles.dominosSecondSectionContainer}>
        <Container>
          <Grid container justify='center'>
            <Grid item xs={4}>
          <aside>
          <p>{props.content.side[0].sub_title}</p>
            <h3>{props.content.side[0].title}</h3>
            <p>{props.content.side[1].sub_title}</p>
            <h3>{props.content.side[1].title}</h3>
            <p>{props.content.side[2].sub_title}</p>
            <h3>{props.content.side[2].title}</h3>
            <p>{props.content.side[3].sub_title}</p>
            <h3>{props.content.side[3].title}</h3>
            <p>{props.content.side[4].sub_title}</p>
            <h3>{props.content.side[4].title}</h3>
              </aside> 
            </Grid>  
            <Grid item xs={8} >
          <h2>{props.content.title}</h2>
            <p>{props.content.paragraph}</p>
            </Grid>
            </Grid>  
          </Container>
        <div>
          <img src={`${process.env.REACT_APP_BASE_URL}${props.content.imgs.url}`} alt=""/>
        </div>
       </div>
    )
}
export default DominosSecondSection;