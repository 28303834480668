import axios from "../../axios";
import { EReduxActionTypes } from "../types/defaultTypes";
import { IJobs } from "../types/index";
import { Dispatch } from "redux";

function JobsData(jobsData: IJobs) {
	return {
		type: EReduxActionTypes.JOBS_DATA,
		jobsData,
	};
}

export function getJobs() {
	return (dispatch: Dispatch) => {
		return new Promise<void>((resolve, reject) => {
			axios.get("https://portal.tacverse.com/api/jobs").then((response) => {
				// console.log(response.data);
				let data = response.data;
				dispatch(JobsData(data));
				resolve();
			});
		});
	};
}
