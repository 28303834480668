import companyLogo1 from "../imgs/companies-logos/company-logo1.png";
import companyLogo2 from "../imgs/companies-logos/company-logo2.png";
import companyLogo3 from "../imgs/companies-logos/company-logo3.png";
import companyLogo4 from "../imgs/companies-logos/company-logo4.png";
import companyLogo5 from "../imgs/companies-logos/company-logo5-gray.png";
import companyLogo6 from "../imgs/companies-logos/company-logo6-gray.png";
import companyLogo7 from "../imgs/companies-logos/company-logo7-gray.png";
import companyLogo8 from "../imgs/companies-logos/company-logo8.png";
import companyLogo9 from "../imgs/companies-logos/company-logo9.png";
import companyLogo10 from "../imgs/companies-logos/company-logo10.png";
import companyLogo11 from "../imgs/companies-logos/company-logo11-gray.png";
import companyLogo12 from "../imgs/companies-logos/company-logo12-gray.png";
import companyLogo13 from "../imgs/companies-logos/company-logo13.png";
import companyLogo14 from "../imgs/companies-logos/company-logo14.png";
import companyLogo15 from "../imgs/companies-logos/company-logo15.png";
import companyLogo16 from "../imgs/companies-logos/company-logo16.png";
import companyLogo17 from "../imgs/companies-logos/company-logo17.png";
import companyLogo18 from "../imgs/companies-logos/company-logo18.png";
import companyLogo19 from "../imgs/companies-logos/company-logo19-gray.png";
import companyLogo20 from "../imgs/companies-logos/company-logo20-gray.png";
import companyLogo21 from "../imgs/companies-logos/company-logo21-gray.png";
import companyLogo22 from "../imgs/companies-logos/company-logo22-gray.png";
import companyLogo23 from "../imgs/companies-logos/company-logo23.png";
import companyLogo24 from "../imgs/companies-logos/company-logo24-gray.png";
import companyLogo25 from "../imgs/companies-logos/company-logo25-gray.png";
import companyLogo26 from "../imgs/companies-logos/company-logo26-gray.png";
import companyLogo27 from "../imgs/companies-logos/company-logo27-gray.png";
import companyLogo28 from "../imgs/companies-logos/company-logo28-gray.png";
import companyLogo29 from "../imgs/companies-logos/company-logo29-gray.png";
import companyLogo30 from "../imgs/companies-logos/company-logo30-gray.png";
import companyLogo31 from "../imgs/companies-logos/company-logo31-gray.png";
import companyLogo32 from "../imgs/companies-logos/company-logo32-gray.png";
import companyLogo33 from "../imgs/companies-logos/company-logo33-gray.png";
import companyLogo34 from "../imgs/companies-logos/company-logo34-gray.png";
import companyLogo35 from "../imgs/companies-logos/company-logo35-gray.png";
import companyLogo36 from "../imgs/companies-logos/company-logo36-gray.png";
import companyLogo37 from "../imgs/companies-logos/company-logo37-gray.png";
import companyLogo38 from "../imgs/companies-logos/company-logo38.png";
import companyLogo39 from "../imgs/companies-logos/company-logo39-gray.png";
import companyLogo40 from "../imgs/companies-logos/company-logo40-gray.png";
import companyLogo41 from "../imgs/companies-logos/company-logo41-gray.png";
import companyLogo42 from "../imgs/companies-logos/company-logo42.png";
import companyLogo43 from "../imgs/companies-logos/company-logo43.png";
import companyLogo44 from "../imgs/companies-logos/company-logo44.png";
import companyLogo45 from "../imgs/companies-logos/company-logo45.png";
import companyLogo46 from "../imgs/companies-logos/company-logo46.png";
import companyLogo47 from "../imgs/companies-logos/company-logo47-gray.png";
import companyLogo48 from "../imgs/companies-logos/company-logo48-gray.png";
import companyLogo49 from "../imgs/companies-logos/company-logo49-gray.png";
import companyLogo50 from "../imgs/companies-logos/company-logo50-gray.png";
import companyLogo51 from "../imgs/companies-logos/company-logo51-gray.png";
import companyLogo52 from "../imgs/companies-logos/company-logo52-gray.png";
import companyLogo53 from "../imgs/companies-logos/company-logo53-gray.png";
import companyLogo54 from "../imgs/companies-logos/company-logo54-gray.png";
import companyLogo55 from "../imgs/companies-logos/company-logo55-gray.png";
import companyLogo56 from "../imgs/companies-logos/company-logo56-gray.png";
import companyLogo57 from "../imgs/companies-logos/company-logo57-gray.png";
import companyLogo58 from "../imgs/companies-logos/company-logo58-gray.png";
import companyLogo59 from "../imgs/companies-logos/company-logo59-gray.png";
import companyLogo60 from "../imgs/companies-logos/company-logo60-gray.png";
import companyLogo61 from "../imgs/companies-logos/company-logo61-gray.png";
import companyLogo62 from "../imgs/companies-logos/company-logo62-gray.png";
import companyLogo63 from "../imgs/companies-logos/company-logo63-gray.png";
import companyLogo64 from "../imgs/companies-logos/company-logo64-gray.png";
import companyLogo65 from "../imgs/companies-logos/company-logo65-gray.png";
import companyLogo66 from "../imgs/companies-logos/company-logo66-gray.png";
import companyLogo67 from "../imgs/companies-logos/company-logo67-gray.png";
import companyLogo68 from "../imgs/companies-logos/company-logo68-gray.png";
import companyLogo69 from "../imgs/companies-logos/company-logo69-gray.png";
import companyLogo70 from "../imgs/companies-logos/company-logo70-gray.png";
import companyLogo71 from "../imgs/companies-logos/company-logo71-gray.png";
import companyLogo72 from "../imgs/companies-logos/company-logo72-gray.png";
import companyLogo73 from "../imgs/companies-logos/company-logo73-gray.png";
import companyLogo74 from "../imgs/companies-logos/company-logo74-gray.png";
import companyLogo75 from "../imgs/companies-logos/company-logo75-gray.png";
import companyLogo76 from "../imgs/companies-logos/company-logo76-gray.png";
import companyLogo77 from "../imgs/companies-logos/company-logo77-gray.png";
import companyLogo78 from "../imgs/companies-logos/company-logo78-gray.png";
import companyLogo79 from "../imgs/companies-logos/company-logo79-gray.png";
import companyLogo80 from "../imgs/companies-logos/company-logo80-gray.png";
import companyLogo81 from "../imgs/companies-logos/company-logo81.png";
import companyLogo82 from "../imgs/companies-logos/company-logo82-gray.png";
import companyLogo83 from "../imgs/companies-logos/company-logo83-gray.png";
import companyLogo84 from "../imgs/companies-logos/company-logo84-gray.png";
import companyLogo85 from "../imgs/companies-logos/company-logo85-gray.png";
import companyLogo86 from "../imgs/companies-logos/company-logo86.png";
import companyLogo87 from "../imgs/companies-logos/company-logo87.png";
import companyLogo88 from "../imgs/companies-logos/company-logo88-gray.png";
import companyLogo89 from "../imgs/companies-logos/company-logo89-gray.png";
import companyLogo90 from "../imgs/companies-logos/company-logo90-gray.png";
// import companyLogo91 from "../imgs/company-logo49.jpg";
// import companyLogo92 from "../imgs/company-logo50.jpg";
// import companyLogo93 from "../imgs/company-logo51.png";
// import companyLogo94 from "../imgs/company-logo52.jpg";
// import companyLogo95 from "../imgs/company-logo53.jpg";
// import companyLogo96 from "../imgs/company-logo54.jpg";
// import companyLogo97 from "../imgs/company-logo55.jpg";
// import companyLogo98 from "../imgs/company-logo56.jpg";
// import companyLogo99 from "../imgs/company-logo57.png";
// import companyLogo100 from "../imgs/company-logo58.png";
// import companyLogo101 from "../imgs/company-logo59.png";
// import companyLogo102 from "../imgs/company-logo60.png";
// import companyLogo103 from "../imgs/company-logo61.png";
// import companyLogo104 from "../imgs/company-logo62.png";
// import companyLogo105 from "../imgs/company-logo63.png";
// import companyLogo106 from "../imgs/company-logo64.jpg";
// import companyLogo107 from "../imgs/company-logo65.jpg";
// import companyLogo108 from "../imgs/company-logo66.jpg";
// import companyLogo109 from "../imgs/company-logo67.jpg";
// import companyLogo110 from "../imgs/company-logo68.jpg";
// import companyLogo111 from "../imgs/company-logo69.png";
// import companyLogo112 from "../imgs/company-logo70.png";
// import companyLogo113 from "../imgs/company-logo71.png";
// import companyLogo114 from "../imgs/company-logo72.png";
// import companyLogo115 from "../imgs/company-logo73.png";
// import companyLogo116 from "../imgs/company-logo74.png";
// import companyLogo117 from "../imgs/company-logo75.png";
// import companyLogo118 from "../imgs/company-logo76.png";
// import companyLogo119 from "../imgs/company-logo77.jpg";
// import companyLogo120 from "../imgs/company-logo78.png";
// import companyLogo121 from "../imgs/company-logo79.png";
// import companyLogo122 from "../imgs/company-logo80.png";
// import companyLogo123 from "../imgs/company-logo81.png";
// import companyLogo124 from "../imgs/company-logo82.png";
// import companyLogo125 from "../imgs/company-logo83.jpg";
// import companyLogo126 from "../imgs/company-logo84.jpg";
// import companyLogo127 from "../imgs/company-logo85.png";





export const sliderData = [
  [companyLogo1, companyLogo7, companyLogo13, companyLogo19],
  [companyLogo2, companyLogo8, companyLogo14, companyLogo20],
  [companyLogo3, companyLogo9, companyLogo15, companyLogo21],
  [companyLogo4, companyLogo10, companyLogo16, companyLogo22],
  [companyLogo5, companyLogo11, companyLogo17, companyLogo23],
  [companyLogo6, companyLogo12, companyLogo18, companyLogo24],

  [companyLogo25, companyLogo31, companyLogo37, companyLogo43],
  [companyLogo26, companyLogo32, companyLogo38, companyLogo44],
  [companyLogo27, companyLogo33, companyLogo39, companyLogo45],
  [companyLogo28, companyLogo34, companyLogo40, companyLogo46],
  [companyLogo29, companyLogo35, companyLogo41, companyLogo47],
  [companyLogo30, companyLogo36, companyLogo42, companyLogo48],

  [companyLogo49, companyLogo55, companyLogo61, companyLogo67],
  [companyLogo50, companyLogo56, companyLogo62, companyLogo68],
  [companyLogo51, companyLogo57, companyLogo63, companyLogo69],
  [companyLogo52, companyLogo58, companyLogo64, companyLogo70],
  [companyLogo53, companyLogo59, companyLogo65, companyLogo71],
  [companyLogo54, companyLogo60, companyLogo66, companyLogo72],

  [companyLogo73, companyLogo79, companyLogo85],
  [companyLogo74, companyLogo80, companyLogo86],
  [companyLogo75, companyLogo81, companyLogo87],
  [companyLogo76, companyLogo82, companyLogo88],
  [companyLogo77, companyLogo83, companyLogo89],
  [companyLogo78, companyLogo84, companyLogo90],

  // [companyLogo91, companyLogo92, companyLogo93, companyLogo94],
  // [companyLogo95, companyLogo96, companyLogo97, companyLogo98],
  // [companyLogo99, companyLogo100, companyLogo101, companyLogo102],
  // [companyLogo103, companyLogo104, companyLogo105, companyLogo106],
  // [companyLogo107, companyLogo108, companyLogo109, companyLogo110],
  // [companyLogo111, companyLogo112, companyLogo113, companyLogo114],

  // [companyLogo115, companyLogo116, companyLogo117, companyLogo118],
  // [companyLogo119, companyLogo120, companyLogo121, companyLogo122],
  // [companyLogo123, companyLogo124, companyLogo125, companyLogo126],
  // [companyLogo127],
  
];
