import React from "react";
import { Container,Grid } from '@material-ui/core';
import styles from './result.module.sass';
import bg from '../../../imgs/mobile-app-going-viral.jpeg';
import bgTwo from '../../../imgs/from-o-to-4k-img.jpeg';



const Result=()=>{
    return(
        <div className={styles.resultContainer}>
            <Container>
                <Grid container justify="center">
                    <Grid item xs={12} style={{textAlign:"center"}}>
                        <h2 className={styles.title}> Results</h2>
                    </Grid>
           
                    <Grid item xs={12} style={{textAlign:"center"}}>
                       <div><img src={bg} alt=""/></div>
                    </Grid>
                    <Grid item xs={12}>
                        <div><img src={bgTwo} alt=""/></div>
</Grid>
                    </Grid>

            </Container> 
            <div className={styles.colorDiv}><p>The sky is not our limit</p></div>      
            
        </div>
    )
}
export default Result;