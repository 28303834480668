import React, { useEffect,useRef,useCallback } from "react";
// import human from '../../../imgs/Human.png'
import arrow from "../../../imgs/Arrow.svg";
import { Container, Grid } from "@material-ui/core";
import styles from "./firstSection.module.sass";
import { IHomeSection } from "../../../redux/types/index";
import { HashLink as Link } from 'react-router-hash-link';

interface IFirstSection {
	content: IHomeSection;
	// textColor: string;
	setWidthAndPosition:(w:number,x:number)=>void
}
function FirstSection(props: IFirstSection) {
	const yellowContainerRef=useRef<HTMLDivElement>(null);
	let {setWidthAndPosition}=props;
	const widthAndPositionHandler=useCallback(()=>{
		if(yellowContainerRef.current){
			const{width,x}=yellowContainerRef.current.getBoundingClientRect();
			setWidthAndPosition(width,x);
	}
	},[setWidthAndPosition])
	useEffect((
	)=>{
		widthAndPositionHandler()
		}
	,[widthAndPositionHandler]);
	
	useEffect(() => {
		window.addEventListener("resize",widthAndPositionHandler);
		return () => window.removeEventListener("resize", widthAndPositionHandler);
	  }, [widthAndPositionHandler]);
	  

	return (
		<div className={styles.sectionContainer} data-color='#000'
		>
			<Link smooth to='#aboutUs' className={styles.scrollDown}>
				<p>scroll down</p>
				<img src={arrow} alt="arrow to scroll down" />
			</Link>
			<Container className={styles.section}>
				<Grid container justify="space-between" alignItems="center">
					<Grid item xs={12} md={5}>
						<h2 data-text={props.content.section_content.title}>
							{props.content.section_content.title}
						</h2>
						{!props.content.section_content.move_content_right ? (
							<button type="button"><a href="https://www.youtube.com/watch?v=d2M6HVrE-1w" target="_blank" rel="noreferrer" >{props.content.section_content.button[0].button_text}</a></button>
						) : null}
					</Grid>
					<Grid item xs={12} md={7} className={styles.firstSectionImgContainer}>
						<Grid container justify="center" alignItems="flex-end">
							<Grid item xs={12} md={7}>
								<div ref={yellowContainerRef} className={styles.firstSectionImg}>
									<img
										src={`${process.env.REACT_APP_BASE_URL}${props.content.section_content.section_image?.url}`}
										alt="astronaut"
									/>
									<img
										src={`${process.env.REACT_APP_BASE_URL}${props.content.section_content.section_image?.url}`}
										alt="astronaut"
									/>
									<img
										src={`${process.env.REACT_APP_BASE_URL}${props.content.section_content.section_image?.url}`}
										alt="astronaut"
									/>
								</div>
							</Grid>
						</Grid>

						{props.content.section_content.move_content_right ? (
							<button type="button">{props.content.section_content.button[0].button_text}</button>
						) : null}
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}

export default FirstSection;
