import { EReduxActionTypes, IReduxBaseAction } from "../types/defaultTypes";

interface IIsLoading extends IReduxBaseAction {
	loading: boolean;
}

export default function isLoading(state = true, action: IIsLoading) {
	switch (action.type) {
		case EReduxActionTypes.LOADING:
			return action.loading;
		default:
			return state;
	}
}
