import React from "react";
import styles from "./theTeam.module.sass";
import NewPerson from "../newPerson/newPerson";
import { Container } from "@material-ui/core";
import { IAboutSection } from "../../../redux/types";


interface IThirdSection {
	content: IAboutSection;
}
const TheTeam = (props:IThirdSection) => {
	return (
		<div className={styles.theteamContainer}>
			<Container>
				<h2>
					{props.content.Title.replace(/\n/g, " ").split(" ")[0]}<br/>
					<span>{props.content.Title.replace(/\n/g, " ").split(" ")[1]}</span>
				</h2>
				<NewPerson data={props.content.Third}/>
							
					
				
		
			</Container>
		</div>
	);
};
export default TheTeam;
