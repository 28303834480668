import React from 'react'
import styles from './dominosFirstSection.module.sass'
import { IDominosSections } from '../../../redux/types';
import { Container } from '@material-ui/core';

interface IFirstSection {
  content: IDominosSections;
}

const DominosFisrtSection = (props:IFirstSection) => {
    return(
      <div className={styles.dominosFirstSectionContainer}>
        <Container>
                <p>{props.content.sub_title}</p>
          <h2>{props.content.title}</h2>
          </Container>
        <div>
          <img src={`${process.env.REACT_APP_BASE_URL}${props.content.imgs.url}`} alt=""/>
        </div>
       </div>
    )
}
export default DominosFisrtSection;