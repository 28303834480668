import React from "react";
import styles from "./newPerson.module.sass";
import { Grid } from "@material-ui/core";
import {  IThird } from "../../../redux/types";

interface personProps {
	data:IThird[]
}
const NewPerson = (props: personProps) => {
	let flag=1;

	return (
		<Grid container spacing={3} >
			{props.data.map((person,index)=>{
									flag++;if(flag===4){flag=1};
				return(
					<Grid  key={index} item xs={4} style={{ alignSelf:flag===3?"flex-end":"flex-start",height:flag===3?"auto":"840px"}} className={styles.gridContainer}>
					<div className={`${styles.personList}`}>
							<div className={styles.imgBox}>
								<img src={`${process.env.REACT_APP_BASE_URL}${person.Person_img.url}`} alt="" />
							</div>
								<div className={styles.descr}>
									<h3>{`${person.Title}`}</h3>
									<p>{person.Sub_title}</p>
								</div>
					</div>
				</Grid>
				)
				
			})}

		</Grid>
	);
};
export default NewPerson;
