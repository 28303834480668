import { EReduxActionTypes, IReduxBaseAction } from '../types/defaultTypes'
import { IHomeSection } from '../types'

interface IHomeData extends IReduxBaseAction {
    homeData: IHomeSection | IHomeSection[]
}

export default function homeData(state = [], action: IHomeData) {
    switch (action.type) {
        case EReduxActionTypes.INITIAL_DATA:
            return action.homeData
        default:
            return state
    }
}