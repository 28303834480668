import React from 'react'
import styles from './caseStudies.module.sass'
import CaseStudy from '../caseStudy/caseStudy'
import { ICaseStudySection } from '../../../redux/types'

interface ISecondSection {
    content: ICaseStudySection;
    textColor: string;
}
const CaseStudies = (props: ISecondSection) => {
    return(
        <div data-color={props.textColor}  className={styles.caseStudiesContainer}>
            <CaseStudy justifyP={styles.justifyS} contentContainer={styles.ctnContainer} img={`${process.env.REACT_APP_BASE_URL}${props.content.caseStudy[0].LogoImg.url}`}
            bg={`${process.env.REACT_APP_BASE_URL}${props.content.caseStudy[0].Bg?.url}`}
            heading={props.content.caseStudy[0].Title}
                paragraph={props.content.caseStudy[0].Paragraph}
                btn_title={props.content.caseStudy[0].Button.Button_text}
                 btn_link="/dove"
             classN={styles.btnCaseStudy}/>
             <CaseStudy  justifyP={styles.justify} contentContainer={styles.ctnContainerW} img={`${process.env.REACT_APP_BASE_URL}${props.content.caseStudy[1].LogoImg.url}`}
            bg={`${process.env.REACT_APP_BASE_URL}${props.content.caseStudy[1].Bg?.url}`}
            heading={props.content.caseStudy[1].Title}
            paragraph={props.content.caseStudy[1].Paragraph}
                classN={styles.btnCaseStudyW}
                btn_title={props.content.caseStudy[1].Button.Button_text}
                btn_link="/cairokee"
            />
             {/* <CaseStudy  justifyP={styles.justifyS} contentContainer={styles.ctnContainerW} img={`${process.env.REACT_APP_BASE_URL}${props.content.caseStudy[2].LogoImg.url}`}
            bg={`${process.env.REACT_APP_BASE_URL}${props.content.caseStudy[2].Bg?.url}`}
            heading={props.content.caseStudy[2].Title}
            paragraph={props.content.caseStudy[2].Paragraph}
                btn_title={props.content.caseStudy[2].Button.Button_text}
                btn_link="/"
                classN={styles.btnCaseStudyW} /> */}
             <CaseStudy justifyP={styles.justify} contentContainer={styles.ctnContainerW} img={`${process.env.REACT_APP_BASE_URL}${props.content.caseStudy[3].LogoImg.url}`}
            bg={`${process.env.REACT_APP_BASE_URL}${props.content.caseStudy[3].Bg?.url}`}
            heading={props.content.caseStudy[3].Title}
            paragraph={props.content.caseStudy[3].Paragraph}
                btn_title={props.content.caseStudy[3].Button.Button_text}
                btn_link="/dominos"
                classN={styles.btnCaseStudyW} />

        </div>
    )
}
export default CaseStudies;