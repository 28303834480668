import React from 'react'
import { useSelector } from 'react-redux';
import { AppState } from '../../redux/reducers';
import { IDominosSections, IHomeSection } from '../../redux/types';
import SubscribeForm from '../subscribeForm/subscribeForm';
import ContactSection from "../home/contactSection/contactSection";
import { faLongArrowAltLeft, faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SliderCustomArrows from "../sliderCustomArrows/sliderCustomArrows";
import styles from './dominos.module.sass'
import DominosFirstSection from './dominosFirtsSection/firstSection';
import Slider from "react-slick";
import DominosFourthSection from './dominosFourthSection/dominosFourthSection';
import DominosSecondSection from './dominosSecondSection/dominosSecondSection';
import DominosThirdSection from './dominosThirdSection/dominosThirdSection';
import { Container } from '@material-ui/core';
const SliderCaseStudy = {
	infinite: true,
	speed: 500,
	slidesToShow: 2,
	slidesToScroll: 1,
	nextArrow: (
		<SliderCustomArrows
			role="slick-next"
			Component={<FontAwesomeIcon icon={faLongArrowAltRight} />}
		/>
	),
	prevArrow: (
		<SliderCustomArrows
			role="slick-prev"
			Component={<FontAwesomeIcon icon={faLongArrowAltLeft} />}
		/>
	),
	arrows: true,
	responsive: [
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
			},
		},
	],
};

// interface IColor{
//   textColor: string;

// }
const Dominos = () => {
  const homeData: IHomeSection[] = useSelector((state: AppState) => state.homeData);

  const dominosData: IDominosSections[] = useSelector((state: AppState) => state.dominosData);
    return(
      <div className={styles.DominosContainer}>
        <DominosFirstSection  content={dominosData[0]} />
        <DominosSecondSection  content={dominosData[1]} />
        <DominosThirdSection content={dominosData[2]} />
        <DominosFourthSection  content={dominosData[3]} />
        <div className={styles.sliderCaseStudyContainer}>
        <Container>
        <Slider {...SliderCaseStudy} className={styles.sliderContainer}>
								{homeData[4].slides.map((slide, index) => {
									return (
										<div key={index} className={styles.slide}>
										{slide.title ? <h2>{slide.title}</h2> : null}
										{slide.paragraph ? <p>{slide.paragraph}</p> : null}
										</div>
									);
								})}
          </Slider>
          </Container>
          </div>

        <ContactSection
				// sectionSubTitle="CONTACT US"
				quote="Ready To Get Started With TAC?"
				link={{ linkText: "GET A QUOTE", linkUrl: "" }}
			/>
			
			<SubscribeForm />
       </div>
    )
}
export default Dominos;