import { Middleware } from 'redux'
const logger: Middleware = store => next => action => {
    // console.group(action)
    // console.log('The Action', action)
    const result = next(action)
    // console.log('The new state is', store.getState())
    console.groupEnd()
    return result
}

export default logger