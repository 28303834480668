import styles from './firstSection.module.sass';
import React from 'react';


const FirstSectionAbout=()=>{
return (
  <div 
    className={styles.firstsectionContainer}>

</div>

)

}
export default FirstSectionAbout;