import { EReduxActionTypes, IReduxBaseAction } from '../types/defaultTypes'
import { IJobs } from '../types'

interface IJobsData extends IReduxBaseAction {
    jobsData: IJobs[]
}

export default function JobsData(state = [], action: IJobsData) {
    switch (action.type) {
        case EReduxActionTypes.JOBS_DATA:
            return action.jobsData
        default:
            return state
    }
}