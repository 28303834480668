import { EReduxActionTypes, IReduxBaseAction } from '../types/defaultTypes'
import { IServicesSection } from '../types'

interface IServicesData extends IReduxBaseAction {
    servicesData: IServicesSection | IServicesSection[]
}

export default function servicesData(state = [], action: IServicesData) {
    switch (action.type) {
        case EReduxActionTypes.SERVICES_DATA:
            return action.servicesData
        default:
            return state
    }
}