import React from 'react';
import cairokee from "../../imgs/cairokee-case-study-new-cover.jpeg";
import cairokeeCaseStudy from "../../imgs/cairokee-case-study-roles-img.jpeg";
import styles from './cairokee.module.sass';
import { Grid } from '@material-ui/core';
import WebsiteShowcase from './websiteShowcase/websiteShowcase';
import CommunityManagement from './communityManagement/communityManagement';
import SocialMedia from "./socialMedia/socialMedia";
import Traffic from './traffic/traffic';
const Cairokee=()=>{
    return(
        <div className={styles.cairokeeContainer}>
                      <Grid container justify="center" spacing={3}>
            <div><img src={cairokee} alt=""/></div>
            <Grid item xs={12}>
            <h2 className={styles.title}><a href="/cairokee">Cairokee</a></h2>
            </Grid>
            <Grid item xs={12} style={{textAlign:"center"}}>
          <div className={styles.portfolioMeta}>
          <span className={styles.subTitle}>Client:</span>
          <a className={styles.link} href='/dove'>Cairokee</a>
          </div>
          <div className={styles.portfolioMeta}><span className={styles.subTitle}>Service:</span>
          <a className={styles.link} href='/dove'>Web Design</a></div>
        </Grid>
        <Grid item xs={10} className={styles.paragraphContainer}>
            <p className={styles.paragraph}><strong>Cairokee</strong> defines their art by intoroducing new melodies and fresh ideas, so their online store had to be loud enough for everyone to tune in.</p>
            <p className={styles.paragraph}>Here’s how we helped Cairokee get an online store through which they can engage their fans and drive their sales skywards.</p>
        </Grid>
        </Grid>
        <div><img src={cairokeeCaseStudy} alt=""/></div>
<WebsiteShowcase/>
<CommunityManagement/>
<SocialMedia/>
<Traffic/>
        </div>
    )
}
export default Cairokee;