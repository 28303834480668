import React from 'react'
import styles from './communityManagement.module.sass'
import { Container,Grid } from '@material-ui/core';
import bg from '../../../imgs/dove-community-management-first-mobile-img-min.jpeg';
import bgTwo from "../../../imgs/dove-community-management-second-mobile-img-min.jpeg";
const CommunityManagement = () => {
    return(
      <div className={styles.communityManagementContainer}>
          <div className={styles.communityManagement}>
         <Container>
                <Grid container justify="center">
                    <Grid item xs={12} style={{textAlign:"center"}}>
                        <h2 className={styles.title}> Community Management </h2>
                    </Grid>
                    <Grid item xs={12}>
<p className={styles.paragraph}><strong> A brand that speaks to you</strong></p>
                    </Grid>
                    <Grid item xs={12}>
<p className={styles.paragraph}>We used different touch-points to serve our social media strategy by re-purposing content to tell the story consistently and effectively across different social platforms.</p>
                    </Grid>
                </Grid>
            </Container>
            </div>

            <div className={`${styles.bg} ${styles.first}`}>
            </div>
            <Grid container>
                <Grid item xs={12}>
                    <div className={styles.bgMobile}><img src={bg} alt=""/></div>
                    <div className={styles.bgMobile}><img src={bgTwo} alt=""/></div>
                </Grid>
            </Grid>
            <div className={`${styles.bg} ${styles.second}`}>
            </div>
            <div className={styles.package}>
         <Container>
                <Grid container justify="center">
                    <Grid item xs={12} style={{textAlign:"center"}}>
                        <p className={styles.title}>PR Campaign: A package of happiness</p>
                    </Grid>
                    <Grid item xs={9}>
<p className={styles.paragraph}>To get the challenge in shape, Dove wrapped its upgraded shampoo in a new package full of surprises.<br/> In order to spread the happiness, the package was distributed to real women across Egypt.</p>
                    </Grid>
                </Grid>
            </Container>
            </div>
            <div className={styles.divColor}></div>
            </div>
    )
}
export default CommunityManagement;