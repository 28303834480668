import { EReduxActionTypes, IReduxBaseAction } from '../types/defaultTypes'
import { IAboutSection } from '../types'

interface IAboutData extends IReduxBaseAction {
    aboutData: IAboutSection | IAboutSection[]
}

export default function aboutData(state = [], action: IAboutData) {
    switch (action.type) {
        case EReduxActionTypes.ABOUT_DATA:
            return action.aboutData
        default:
            return state
    }
}