import React from 'react'
import { CustomArrowProps } from 'react-slick'

interface ISliderCustomArrows extends CustomArrowProps {
    Component: JSX.Element
    classes?: string[]
    role?: string
}

export default function SliderCustomArrows(props: ISliderCustomArrows) {
    const { Component, className, currentSlide, slideCount, role, ...subProps } = props

    return (
        <button {...subProps} data-role={role} className={`${props.classes?.join(" ")}`}>
            {Component}
        </button>
    )
}