import { Container } from '@material-ui/core';
import React, { useEffect} from 'react';
import styles from './news.module.sass';
// import SimpleTabs from './tabsForNews/tabsForNews';
import ContactSection from "../home/contactSection/contactSection"
import SubscribeForm from "../subscribeForm/subscribeForm";
// import { AppState } from '../../redux/reducers';
import { INewsSection } from '../../redux/types';
// import { useSelector } from 'react-redux';
import ItemNews from "./itemNews/itemNews";
import { Grid } from "@material-ui/core";
// import NewsInner from "./newsInner/newsInner";
import { Link } from "react-router-dom"
import {Eid} from './itemNews/eid'

interface IColor{
    // textColor: string;
    // updateHeaderColor: (color: string ) => void;
    content: INewsSection;
}
const News = (props: IColor) => {
    // const handleBackForNews = () => {
	// 	setDisplayNews((showNews = true));
	// };
    // let showNews = true;
    // const [selectedNew, setSelectedNew] = useState<Iarticle>();
	// const [displayNews, setDisplayNews] = useState<any>(showNews);
    // const handleClickOnNews = (id: number, typeOfnews: TDataNewObject[]): any => {
	// const handleClickOnNews = (id: number, itemType:Iarticle[]): any => {
	// 	let newSelected = itemType.find((news) => news.id === id);
	// 	setSelectedNew(newSelected);
	// 	setDisplayNews((showNews = false));
	// 	// console.log(displayNews);
	// };
    // const sectionContainer = useRef<HTMLDivElement>(null);
    // let textColor = sectionContainer.current?.dataset.color ?? '#000';
    // props.updateHeaderColor(textColor)
    useEffect(() => {
        // props.updateHeaderColor(textColor)
		// const theLink = item.tit.replace(/%20/g, "-");
		// props.content.articles
		

    })
    // const newsData: INewsSection[] = useSelector((state: AppState) => state.newsData);
    return(
        <div className={styles.newsContainer}  >
            <Container>
            {/* {displayNews ? ( */}
            <Grid container justify="space-between">
							{props.content.articles.map((item) =>
									<	Grid item xs={12} md={6} key={item.id.toString()}>
											
																			<Link to={`/new/${item.title.replace(/[^a-zA-Z ]/g, "").replace(/\s/g,"-")}`}>	
																				
								<ItemNews
								// clicked={() => handleClickOnNews(item.id,props.content.articles)}
								title={item.title}
								sub_title={item.sub_title}
								paragraph={item.paragraph}
								type={item.type}
								date={item.date}
								img={item.img}
								id={item.id}
								/>
			</Link>	
			
			</Grid>

			)}
			
			<	Grid item xs={12} md={6}>
			<Link to={`/new/Greetings-The-Egyptian-Way`}>
				<Eid/>
								</Link>
			</Grid>
			</Grid>
			{/* ):( */}
            {/* // <NewsInner */}
			{/* // 		handleBackIcon={handleBackForNews}
			// 			title={selectedNew?.title}
			// 			sub_title={selectedNew?.sub_title}
			// 		types={selectedNew?.type}
			// 		typeOfNews={selectedNew?.type}
			// 		dateForNews={selectedNew?.date}
			// 		imgForNews={selectedNew?.img.url}
			// 		paragraph={selectedNew?.paragraph}
			// 	/>
				// )} */}
                {/* <SimpleTabs content={newsData[0]}/> */}
				
</Container>
            <ContactSection
				// sectionNumber="006"
				// sectionSubTitle="CONTACT US"
				quote="Ready To Get Started With TAC?"
				link={{ linkText: "GET A QUOTE", linkUrl: "" }}
			/>
            <SubscribeForm/>
        </div>
    )
}
export default News;