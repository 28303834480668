import React from "react";
import { Container,Grid } from '@material-ui/core';
import styles from './traffic.module.sass';
import trafficTop from '../../../imgs/cairokee-trrafic-top-img.jpeg';
import trafficLeft from '../../../imgs/cairokee-trrafic-left-img.jpeg';
const Traffic=()=>{
    return(
        <div className={styles.trafficContainer}>
            <Container>
                <Grid container justify="center" spacing={3}>
                    <Grid item xs={12} style={{textAlign:"center"}}>
                        <h2 className={styles.title}> Traffic </h2>
                    </Grid>
                    <Grid item xs={11} className={styles.trafficContent}>
                        <Grid container justify="center" spacing={3}>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={8}>
                    <img src={trafficTop} alt=""/>
                    </Grid>
                    <Grid item xs={12}>
                    <p className={styles.challengeContent}>Using social media campaigns to fuel the game and to add extra traffic to the E-commerce website, we utilized all what Social Media platforms had to offer.</p>
                    <p className={styles.challengeContent}>In the FIRST WEEK of the second social media campaign, Cairokee Store achieved the same sales target that the previous campaign achieved.</p>
                    </Grid>

                    </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <img src={trafficLeft} alt=""/>
                    </Grid>
                    </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
export default Traffic;