import axios from "../../axios";
import { EReduxActionTypes } from "../types/defaultTypes";
import { ICaseStudySection } from "../types/index";
import { Dispatch } from "redux";

function CaseStudyData(caseStudyData: ICaseStudySection[]) {
	return {
		type: EReduxActionTypes.CASESTUDY_DATA,
		caseStudyData,
	};
}

export function getCaseStudyData() {
	return (dispatch: Dispatch) => {
		return new Promise<void>((resolve, reject) => {
			axios.get("/case-Studies-sections").then((response) => {
				// console.log(response);
				let data = response.data;
				dispatch(CaseStudyData(data));
				resolve();
			});
		});
	};
}
