import { Container, Grid } from '@material-ui/core'
import React from 'react'
import { ICaseStudySection } from '../../../redux/types'
import styles from './firstSection.module.sass'

interface IFirstSection {
    content: ICaseStudySection;
    textColor: string | undefined;
}
const FirstSection=(props:IFirstSection)=>{
    return(
        <div data-color={props.textColor} className={styles.firstSectionContainer}>
            <Container>
                <h2>{`${props.content.Title.replace(/\n/g, " ").split(" ")[0]} ${props.content.Title.replace(/\n/g, " ").split(" ")[1]}`}<br />
                    <span>{`${props.content.Title.replace(/\n/g, " ").split(" ")[2]} ${props.content.Title.replace(/\n/g, " ").split(" ")[3]}`}</span>
                </h2>
<Grid container>
    <Grid item md={7}>
                <p>{props.content.Paragraph.replace("!","!#").split("#")[0]}
                    <br/> {props.content.Paragraph.replace("!","!#").split("#")[1]}</p>
                    </Grid>
                    </Grid>
                </Container>
        </div>
    )
}
export default FirstSection;
