import { Container, Grid } from "@material-ui/core";
import React from "react";
import styles from "./clickedSection.module.sass";
import SliderItem from "../sliderItem/sliderItem";
import ClickedSectionSliderImage from "../clickedSectionSliderImage/clickedSectionSliderImage";
// import InnerClickedSection from "../innerClickedSection/innerClickedSection";
import Slider from "react-slick";
import SliderCustomArrows from "../../sliderCustomArrows/sliderCustomArrows";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft, faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { IsliderForServices} from "../../../redux/types";

interface sliderServices extends IsliderForServices{
	textColor: string;
clicked:(event: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>void
}


const ClickedSection = (props:sliderServices, ref: any) => {
	var settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 2,
		nextArrow: <SliderCustomArrows role="slick-next" Component={<FontAwesomeIcon icon={faLongArrowAltRight} />} />,
		prevArrow: <SliderCustomArrows role="slick-prev" Component={<FontAwesomeIcon icon={faLongArrowAltLeft} />} />,
		arrows: true,
		responsive: [
			{
				breakpoint: 900,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 700,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};
	// const innerClickedSectionRef = useRef<HTMLDivElement>();
	// const [selected, setSelected] = useState<IsubSlider>();
	// const [changeSection, setChangeSection] = useState<boolean>(false);
// const[clicked,setClicked]=useState<boolean>(true);
	// const handleSlide = (id: number) => {
	// 	setClicked(false);
	// 	let newSelectedInnerSlider = props.sub_slider.find((item) => item.id === id);
	// 	setSelected(newSelectedInnerSlider);
	// 	setChangeSection(true);

		
	// 	// console.log(selected)

	// 	if (newSelectedInnerSlider && innerClickedSectionRef.current) {
	// 		window.scroll({
	// 			behavior: "smooth",
	// 			top:
	// 				innerClickedSectionRef.current.getBoundingClientRect().top +
	// 				document.documentElement.scrollTop,
	// 		});
	// 	}
	// };
	
	// useEffect(()=>{
	// 	if(clicked){
	// 		setChangeSection(props.changeSection);
	// 		console.log("again")
	// 		// setClicked(true)
	// 	}
	
	// },[clicked])
	// useEffect(() => {
	// 	// console.log(selected);
	// 	// console.log(clickedSectionRef.current?.getBoundingClientRect().top)
	// 	if (innerClickedSectionRef.current) {
	// 		window.scroll({
	// 			behavior: "smooth",
	// 			top:
	// 				innerClickedSectionRef.current.getBoundingClientRect().top +
	// 				document.documentElement.scrollTop,
	// 		});
	// 		// console.log(clickedSectionRef)
	// 	}
	// }, [selected,changeSection]);
	// {console.log(changeSection,innerClickedSectionRef.current,`click${clicked}`)}

	return (
		<div id='clickedSection' ref={ref} data-color={props.textColor}>
			<div className={styles.brandingAndDesignContainer}>
				<Container>
					<Grid container justify="space-between">
						<Grid item xs={12} md={5}>
							<div>
								<h3>
									{props.title} <br />
									</h3>
								<p>{props.paragraph}</p>
							</div>
						</Grid>
						<Grid item xs={12} md={6}>
							<div>
								{props.img_slider.map((imgSlider) => {
										return 		<ClickedSectionSliderImage key={imgSlider.img.toString()} img={imgSlider.img} />
							})}
							</div>
						</Grid>
					</Grid>

					<div className={styles.BrandingAndDesignSlidertwo}>
						<i>{props.sub_title}</i>
						<Slider {...settings} className={styles.sliderForClickedSection}>
							{props.sub_slider.map((item) => {
								return <SliderItem key={item.id} heading={item.title} id={item.id.toString()}  clicked={props.clicked}  />;
							})}
						</Slider>
					</div>
				</Container>
			</div>
			{/* branding and design container */}
			{/* {changeSection && <div>hiiiiiiihii</div> }
				{selected &&  (
					<InnerClickedSection
					ref={innerClickedSectionRef}
						title={selected.title}
						paragraph={selected.paragraph}
						span={selected.span}
						sub_title={selected.sub_title}
						img={selected.img}
						id={selected.id}
					/>
				)
			} */}
							
		</div>
	);
};
const forwaredClickedSection=React.forwardRef(ClickedSection)
export default forwaredClickedSection;
