import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import SliderCustomArrows from "../../sliderCustomArrows/sliderCustomArrows";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft, faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import styles from "./clickedSectionSLiderImage.module.sass";
import { RequestedImage } from "../../../redux/types/defaultTypes";

interface images {
	img: RequestedImage[];
}

const ClickedSectionSliderImage = (props: images) => {
	var settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: <SliderCustomArrows role="slick-next" Component={<FontAwesomeIcon icon={faLongArrowAltRight} />} />,
		prevArrow: <SliderCustomArrows role="slick-prev" Component={<FontAwesomeIcon icon={faLongArrowAltLeft} />} />,
		arrows: true,
	};
	return (
		<div className={styles.clickedSectionSliderImageContainer}>
			<Slider {...settings}>
				{props.img.map((img) => {
						return (
							<div key={img.id.toString()} >
					<img src={`${process.env.REACT_APP_BASE_URL}${img.url}`}  alt="" />
							</div>
						 );
			} )}
			</Slider>
		</div>
	);
};
export default ClickedSectionSliderImage;
