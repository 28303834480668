import React from 'react'
import { Container, Grid } from "@material-ui/core"
import { Link } from "react-router-dom"
import styles from './assets/footer.module.sass'
import logoWhite from '../../imgs/tac-universe-logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faBehance, faPinterestP, faLinkedinIn, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'

export default function Footer() {
    return (
        <footer className={styles.footerContainer}>
            <div>
                <Container>
                    <Grid container justify="space-between" alignItems="center" spacing={5}>
                        <Grid item xs={12} md={5} classes={{ root: styles.findUs }}>
                            <img src={logoWhite} alt="Company Logo White" />
                            <p>FIND US ON</p>
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/theappconcept" rel="noreferrer" target="_blank">
                                        <FontAwesomeIcon icon={faFacebookF} />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.behance.net/tacuniverse" rel="noreferrer" target="_blank">
                                        <FontAwesomeIcon icon={faBehance} />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.pinterest.com/tacuniverse/" target="_blank" rel="noreferrer">
                                        <FontAwesomeIcon icon={faPinterestP} />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/tacuniverse/" target="_blank" rel="noreferrer">
                                        <FontAwesomeIcon icon={faLinkedinIn} />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/tacuniverse" target="_blank" rel="noreferrer">
                                        <FontAwesomeIcon icon={faTwitter} />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/tac.universe/" target="_blank" rel="noreferrer">
                                        <FontAwesomeIcon icon={faInstagram} />
                                    </a>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className={styles.officeLocation}>
                                <h3>CAIRO OFFICE</h3>
                                <p>9, Street 9, Maadi, Cairo, Egypt</p>
                            </div>
                            <div className={styles.officeLocation}>
                                <h3>ALEXANDRIA OFFICE</h3>
                                <p>1, Kafr Abou Street, Kafr Abdou, Alexandria, Egypt</p>
                            </div>
                            <div className={styles.officeLocation}>
                                <h3>TELEPHONE</h3>
                                <p>+20-122-555-9843</p>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div>
                <Container maxWidth="xl">
                    <Grid container justify="center" alignItems="center">
                        <Grid className={styles.bottomBarContianer} container item xs={12} md={10} justify="space-between" alignItems="center" spacing={3}>
                            <Grid container justify="flex-start" item xs={12} md={5}>
                                <p>2020 © TAC UNIVERSE</p>
                            </Grid>
                            <Grid item xs={12} md={6} container justify="flex-end">
                                <ul>
                                    <li>
                                        <Link to="/">TERMS OF USE</Link>
                                    </li>
                                    <li>
                                        <Link to="/">PRIVACY POLICY</Link>
                                    </li>
                                </ul>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </footer>
    )
}