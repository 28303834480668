import React from 'react'
import styles from './dominosFourthSection.module.sass'
import { IDominosSections } from '../../../redux/types';
import { Container, Grid } from '@material-ui/core';

interface IFourthSection {
  content: IDominosSections;
}

const DominosFourthSection = (props:IFourthSection) => {
    return(
      <div className={styles.dominosFourthSectionContainer}>
        <Container>
        <Grid container justify='center'>
            <Grid item xs={4}>
              <h2>{props.content.title}</h2>
              </Grid>
              <Grid item xs={8}>
              <p>{props.content.paragraph}</p>
              </Grid>
            </Grid> 
          </Container>
        <div>
          <img src={`${process.env.REACT_APP_BASE_URL}${props.content.imgs.url}`} alt=""/>
        </div>
       </div>
    )
}
export default DominosFourthSection;