import { EReduxActionTypes, IReduxBaseAction } from '../types/defaultTypes'
import { IDominosSections } from '../types'

interface IDominosData extends IReduxBaseAction {
    dominosData: IDominosSections | IDominosSections[]
}

export default function dominosData(state = [], action: IDominosData) {
    switch (action.type) {
        case EReduxActionTypes.DOMINOS_DATA:
            return action.dominosData
        default:
            return state
    }
}