import React, { useCallback } from "react";
import styles from "./assets/home.module.sass";
import FirstSection from "./firstSection/firstSection";
import SecondSection from "./secondSection/secondSection";
import SectionWithSlider from "../sectionWithSlider/sectionWithSlider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft, faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/reducers";
import SliderCustomArrows from "../sliderCustomArrows/sliderCustomArrows";
import { IHomeSection } from "../../redux/types";
import ContactSection from "./contactSection/contactSection";
import SubscribeForm from "../subscribeForm/subscribeForm";
import { sliderData } from "../../data/home-slider-data";
import { Container, Grid } from "@material-ui/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ThirdSection from "./thirdSection/thirdSection";
///////////
const SliderWithoutArrows = {
	infinite: false,
	speed: 500,
	slidesToShow: 3,
	slidesToScroll: 1,
	arrows: false,
	responsive: [
		{
			breakpoint: 786,
			settings: {
				slidesToShow: 1.1,
				slidesToScroll: 1,
				arrows: true,
				nextArrow: (
					<SliderCustomArrows
						role="slick-next"
						Component={<FontAwesomeIcon icon={faLongArrowAltRight} />}
					/>
				),
				prevArrow: (
					<SliderCustomArrows
						role="slick-prev"
						Component={<FontAwesomeIcon icon={faLongArrowAltLeft} />}
					/>
				),
				
			},
		},
		{
			breakpoint: 568,
			settings: {
				slidesToShow: 1.1,
				slidesToScroll: 1,
				arrows: true,
				nextArrow: (
					<SliderCustomArrows
						role="slick-next"
						Component={<FontAwesomeIcon icon={faLongArrowAltRight} />}
					/>
				),
				prevArrow: (
					<SliderCustomArrows
						role="slick-prev"
						Component={<FontAwesomeIcon icon={faLongArrowAltLeft} />}
					/>
				),
				
			},
		},
	],
};
///////
const SliderShowThreeSlidesWithWhiteArrows = {
	infinite: true,
	speed: 500,
	slidesToShow: 3,
	slidesToScroll: 1,
	nextArrow: (
		<SliderCustomArrows
			role="slick-next"
			Component={<FontAwesomeIcon icon={faLongArrowAltRight} />}
		/>
	),
	prevArrow: (
		<SliderCustomArrows
			role="slick-prev"
			Component={<FontAwesomeIcon icon={faLongArrowAltLeft} />}
		/>
	),
	arrows: true,
	responsive: [
		{
			breakpoint: 786,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: true,
			},
		},
		{
			breakpoint: 568,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: true,
			},
		},
	],
};

const SliderShowThreeSlidesWithBlackArrows = {
	infinite: true,
	speed: 500,
	slidesToShow: 3,
	slidesToScroll: 1,
	nextArrow: (
		<SliderCustomArrows
			role="slick-next"
			classes={[styles.articles]}
			Component={<FontAwesomeIcon icon={faLongArrowAltRight} />}
		/>
	),
	prevArrow: (
		<SliderCustomArrows
			role="slick-prev"
			classes={[styles.articles]}
			Component={<FontAwesomeIcon icon={faLongArrowAltLeft} />}
		/>
	),
	arrows: true,
	responsive: [
		{
			breakpoint: 786,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: true,
			},
		},
		{
			breakpoint: 568,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: true,
			},
		},
	],
};

const SliderShowSixSlides = {
	infinite: true,
	speed: 500,
	slidesToShow: 6,
	slidesToScroll: 6,
	nextArrow: (
		<SliderCustomArrows
			classes={[styles.tacClients]}
			role="slick-next"
			Component={<FontAwesomeIcon icon={faLongArrowAltRight} />}
		/>
	),
	prevArrow: (
		<SliderCustomArrows
			classes={[styles.tacClients]}
			role="slick-prev"
			Component={<FontAwesomeIcon icon={faLongArrowAltLeft} />}
		/>
	),
	arrows: true,
	responsive: [
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
				arrows: true,
			},
		},
	],
};
interface IColor{
	updateHeaderColor?: (color: string ) => void;
	textColor?: string ;
	setWAndP:(w:number,x:number)=>void

}

function Home(props:IColor) {
	// const sectionContainer = useRef<HTMLDivElement>(null);
	// useEffect(() => {
	// 	let callback = (
    //   entries: IntersectionObserverEntry[],
    //   observer: object
	// 	): void => {
	// 		entries.forEach((entry) => {
	// 			// console.log(entry.target)
	// 			if (entry.intersectionRatio >= 0.4) {
	// 				// console.log(entry)
	// 				if (entry.target instanceof HTMLDivElement) {
	// 					let textColor = entry.target.dataset.color ?? "#000"; 
	// 					// console.log(textColor)
	// 					updateHeaderColor(textColor);
    //       }
    //     }
    //   });
    // };
    // let observer = new IntersectionObserver(callback, {
    //   threshold: [0.4],
	// 	});
    // let secondSections: Array<Element>;
    // if (
    //   sectionContainer &&
    //   sectionContainer.current &&
    //   sectionContainer.current.children
	// 	) {
	// 		secondSections = Array.from(sectionContainer.current.children);
	// 		// console.log(secondSections)
    //   secondSections.forEach((section) => {
	// 			observer.observe(section);
	// 		});
	// 	}
	// },[updateHeaderColor,sectionContainer])
	const homeData: IHomeSection[] = useSelector((state: AppState) => state.homeData);
// const whichCaseStudy=()=>{
// console.log(whichCaseStudy)
// }
// const whichBlog=()=>{
	
// }
let {setWAndP}=props;
const widthAndPositionHandler=useCallback((w:number,x:number)=>{
	setWAndP(w,x)
	},[setWAndP]) 
	return (
		<div>
				{/* <div className="sticky-copyright">
					<p>
						powered by{" "}
						<a href="https://tacuniverse.com" target="_blank" rel="noopener noreferrer">
							TAC UNIVERSE
						</a>
					</p>
				</div> */}
			<FirstSection content={homeData[0]}  setWidthAndPosition={widthAndPositionHandler}/>
			<SecondSection content={homeData[1]} textColor='#fff'/>
			<ThirdSection
				textColor='#fff'
				classes={[styles.generalSlider, styles.smallScaleImage]}
				// BGColor="#FCEE21"
				slides={homeData[2].slides}
				settings={SliderShowThreeSlidesWithWhiteArrows}
				sectionNumber={homeData[2].section_content.section_number ?? ""}
				sectionTitle={homeData[2].section_content.title}
				sectionSubTitle={homeData[2].section_content.sub_title}
				sectionParagraphs={homeData[2].paragraphs}
				button={homeData[2].section_content.button[0]}
			/>
			{/* <SectionWithSlider
					classes={[styles.tacClients]}
					BGColor="#fff"
					slides={sliderData}
					settings={SliderShowSixSlides}
					sectionNumber={homeData[3].section_content.section_number ?? ""}
					sectionTitle={homeData[3].section_content.title}
					sectionSubTitle={homeData[3].section_content.sub_title}
					sectionParagraphs={homeData[3].paragraphs}
				/> */}
			<div data-color={'#000'}
				className={`${styles.tacClients} ${styles.sectionContainer}`}
				style={{ backgroundColor: "#fff"}}>
				<Container>
					<Grid container alignItems="center">
						<Grid item xs={12} md={12}>
							<p>
								<span>03</span> OUR CLIENTS
							</p>
							<Grid container justify="space-between">
								<Grid item xs={12} md={7}>
									<h2>TAC<br/>CLIENTS</h2>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Slider {...SliderShowSixSlides} className={styles.sliderContainer}>
								{sliderData.map((slide, index) => {
									return (
										<div key={index} className={styles.slide}>
											<div>
												{slide.map((image, index) => {
													return (
														<div key={index}>
															<img src={`${image}`} alt="slider img" style={image.includes('-gray')?{filter:"grayscale(1)"}:{}} />
														</div>
													);
												})}
											</div>
										</div>
									);
								})}
							</Slider>
						</Grid>
					</Grid>
				</Container>
			</div>
		<SectionWithSlider
				textColor='#fff'
				classes={[styles.generalSlider]}
				BGColor="#202020"
				slides={homeData[4].slides}
				settings={SliderWithoutArrows}
				sectionNumber={homeData[4].section_content.section_number ?? ""}
				sectionTitle={homeData[4].section_content.title}
				sectionSubTitle={homeData[4].section_content.sub_title}
				sectionParagraphs={homeData[4].paragraphs}
				button={homeData[4].section_content.button[0]}
				// click={()=>whichCaseStudy()}
			/>
			<SectionWithSlider
				textColor='#000'
				classes={[styles.generalSlider, styles.articles]}
				BGColor="#fff"
				slides={homeData[5].slides}
				settings={SliderShowThreeSlidesWithBlackArrows}
				sectionNumber={homeData[5].section_content.section_number ?? ""}
				sectionTitle={homeData[5].section_content.title}
				sectionSubTitle={homeData[5].section_content.sub_title}
				sectionParagraphs={homeData[5].paragraphs}
				button={homeData[5].section_content.button[0]}
				// click={()=>whichBlog()}
			/>
			<ContactSection
				// sectionSubTitle="CONTACT US"
				quote="Ready To Get Started With TAC?"
				link={{ linkText: "GET A QUOTE", linkUrl: "" }}
			/>
			<SubscribeForm />
		</div>
	);
}

export default Home;
