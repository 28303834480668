import { Container, Grid } from "@material-ui/core";
import React, { useState, useEffect, useRef} from "react";
import styles from "./firstSection.module.sass";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import SliderItem from "../sliderItem/sliderItem";
import SliderCustomArrows from "../../sliderCustomArrows/sliderCustomArrows";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft, faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import ClickedSection from "../clickedSection/clickedSection";
import { IServicesSection, IsliderForServices, IsubSlider } from "../../../redux/types";
import InnerClickedSection from "../innerClickedSection/innerClickedSection";
// import SplitText from '../../splitText/splitText'
// import { text } from "@fortawesome/fontawesome-svg-core";

interface IColor{
	// textColor: string;
	content: IServicesSection;
}

const FirstSection = (props: IColor, slider: IsliderForServices) => {  
	const innerClickedSectionRef = useRef<HTMLDivElement>();
	const [selectedInner, setSelectedInner] = useState<IsubSlider>();
	const clickedSectionRef = useRef<HTMLDivElement>(null);
	const [selected, setSelected] = useState<IsliderForServices>();
	const handleSlide = (id: number) => {
		let newSelected = props.content.slider.find((item)=>item.id === id);
		setSelected(newSelected);
	setSelectedInner(undefined);
		if (newSelected && clickedSectionRef.current) {
			window.scroll({
				behavior: "smooth",
				top:
					clickedSectionRef.current.getBoundingClientRect().top +
					document.documentElement.scrollTop,
			});
			console.log(clickedSectionRef)
		}
		else {
			window.scroll({
				behavior: "smooth",
				top:
					600 +
					document.documentElement.scrollTop,
			});
		}
	};
	const handleSlideTwo = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		let newSelectedInnerSlider =selected?.sub_slider.find((item) => item.id === parseInt(event.currentTarget.id));
		setSelectedInner(newSelectedInnerSlider);
		// if (newSelectedInnerSlider && innerClickedSectionRef.current) {
		// 	window.scroll({
		// 		behavior: "smooth",
		// 		top:
		// 			innerClickedSectionRef.current.getBoundingClientRect().top +
		// 			document.documentElement.scrollTop,
		// 	});
		// }
	};
	useEffect(() => {
		// console.log(selected);
		// console.log(clickedSectionRef.current?.getBoundingClientRect().top)
		if (clickedSectionRef.current) {
			window.scroll({
				behavior: "smooth",
				top:
					clickedSectionRef.current.getBoundingClientRect().top +
					document.documentElement.scrollTop,
				
			});
			// console.log(clickedSectionRef)
		}
		if (innerClickedSectionRef.current) {
					window.scroll({
						behavior: "smooth",
						top:
							innerClickedSectionRef.current.getBoundingClientRect().top +
							document.documentElement.scrollTop,
					});
					// console.log(clickedSectionRef)
				}

	}, [selected,selectedInner]);

	var settings = {
		dots: false,
		infinite: false,
		nextArrow: <SliderCustomArrows role="slick-next" Component={<FontAwesomeIcon icon={faLongArrowAltRight} />} />,
		prevArrow: <SliderCustomArrows role="slick-prev" Component={<FontAwesomeIcon icon={faLongArrowAltLeft} />} />,
		arrows: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 2,
		responsive: [
			{
				breakpoint: 900,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 700,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};
	return (
		<div className={styles.firstSectionContainer}>
			<div className={styles.bgGrey}>
				<Container>
					<h2>
					{props.content.title.replace(/\n/g, " ").split(" ")[0]}<br/>
					<span> {props.content.title.replace(/\n/g, " ").split(" ")[1]} {props.content.title.replace(/\n/g, " ").split(" ")[2]}</span><br />
					</h2>

					<Grid container>
						<Grid item md={8} xs={12}>
							<p>
								{props.content.paragraph}
							</p>
						</Grid>
					</Grid>

					<div className={styles.titleContainer}>
						<h3>{props.content.sub_title}</h3>
						<i>{props.content.span}</i>
					</div>
					<Grid container>
						<Grid item xs={11}>
					<Slider {...settings} className={styles.sliderForServices}>
								{props.content.slider.map((item) => {
									return	<SliderItem key={item.id.toString()} heading={item.title} id={item.id.toString()} clicked={() => handleSlide(item.id)} />;
								})}
							</Slider>
						</Grid>
					</Grid>
				</Container>
			</div>
			{selected && (
				<ClickedSection
				 textColor='#000'
					ref={clickedSectionRef}
					title={selected.title}
					sub_title={selected.sub_title}
					paragraph={selected.paragraph}
					img_slider={selected.img_slider}
					sub_slider={selected.sub_slider}
					id={selected.id}
					clicked={handleSlideTwo}
				/>
			)}
				{/* branding and design container */}
				{selectedInner &&  (
					<InnerClickedSection
					ref={innerClickedSectionRef}
						title={selectedInner.title}
						paragraph={selectedInner.paragraph}
						span={selectedInner.span}
						sub_title={selectedInner.sub_title}
						img={selectedInner.img}
						id={selectedInner.id}
					/>
				)
			}
		</div>
	);
};
export default FirstSection;
