import { Container } from "@material-ui/core";
import React from "react";
import styles from "./downloadOurProfile.module.sass";


const DownloadOurProfile = () => {
	return (
		<div
			className={styles.downloadProfileContainer}>
			<div className={styles.line}></div>
			<Container>
				<h3>You Can See Much More!</h3>
				<h2>
					<a href="https://api.theappconcept.com/uploads/tac-universe-company-profile-2020.pdf" target="_blank" rel="noreferrer">
						DOWNLOAD OUR <br /> COMPANY PROFILE
					</a>
				</h2>
			</Container>
		</div>
	);
};
export default DownloadOurProfile;
