export enum EReduxActionTypes {
    INITIAL_DATA = 'INITIAL_DATA',
    ABOUT_DATA = 'ABOUT_DATA',
    CASESTUDY_DATA = 'CASESTUDY_DATA',
    SERVICES_DATA = 'SERVICES_DATA',
    NEWS_DATA = 'NEWS_DATA',
    DOMINOS_DATA='DOMINOS_DATA',
    LOADING = 'LOADING',
    JOBS_DATA = 'JOBS_DATA',
}

export interface IReduxBaseAction {
    type: EReduxActionTypes
}

export interface DefaultResponse {
    id: number
    created_at: string
    updated_at: string
}

export interface RequestedImage extends DefaultResponse {
    name?: string
    hash?: string
    sha256?: string
    ext?: string
    mime?: string
    size?: number
    url?: string
    provider?: string
    provider_metadata?: null
}