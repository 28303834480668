import React from "react";
import { Container,Grid } from '@material-ui/core';
import styles from './socialMedia.module.sass';
import bg from '../../../imgs/cairokee-from-band-to-band.jpeg';

const SocialMedia=()=>{
    return(
        <div className={styles.socialMediaContainer}>
            <Container>
                <Grid container justify="center">
                    <Grid item xs={12} style={{textAlign:"center"}}>
                        <h2 className={styles.title}> Social Media </h2>
                    </Grid>
                </Grid>
            </Container>
           <div className={styles.purchase}>
               <Container maxWidth="lg">
                   <Grid container justify="space-around">
                       <Grid item xs={4}>
                          
<div className={styles.paragraphContent}>
<p className={styles.purchaseTitle}>From Band to Brand</p>
                           <p className={styles.purchaseParagraph}>By speaking and acting with one voice across all touch points and providing a platform for communication with fans, the online store was actually able to drive sales up, and also make a statement on Social Media by evoking shared values and personality.</p>
                         
</div>
<div className={styles.paragraphContent}>

<p className={styles.purchaseTitle}>Tuning in the fans’ Experience</p>
                           <p className={styles.purchaseParagraph}>TAC Universe started by identifying the critical touch points of the fans’ digital interaction with Cairokee, using them to bolster the band’s genuine identity.</p>
                         </div>
                           </Grid>
                       <Grid item xs={6}>
                       <div className={styles.paragraphContent}>
                           <div><img src={bg} alt=""/></div>
                           </div>
                           </Grid>
                   </Grid>
               </Container>
           </div>
           <div className={styles.colorDiv}></div>
        </div>
    )
}
export default SocialMedia;