import React from "react";
import styles from "./humbleBeginnings.module.sass";
import { Grid, Container } from "@material-ui/core";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";
import { IAboutSection } from "../../../redux/types";


interface ISecondSection {
	content: IAboutSection;
}

const HumbleBeginnings = (props: ISecondSection) => {
	return (
		<div 
			id="moveImg" className={styles.humblebeginningsContainer}>
			<Container>
				<Grid container justify="space-between">
					<Grid item xs={12} md={5}>
						<div className={styles.headandimg}>
							<h2>
								{props.content.Second?.Title.replace(/\n/g, " ").split(" ")[0]}<br/><span> {`${props.content.Second?.Title.replace(/\n/g, " ").split(" ")[1]} ${props.content.Second?.Title.replace(/\n/g, " ").split(" ")[2]} ${props.content.Second?.Title.replace(/\n/g, " ").split(" ")[3]}`}</span>
							</h2>
							<div className={styles.image}>
								<Controller>
									<Scene duration={1300} triggerElement="#moveImg">
										{(progress: any) => (
											<Timeline totalProgress={progress} paused>
												<Tween
													from={{ transform: "translate(0%, 0%) matrix(1, 0, 0, 1, 0, 0)" }}
													to={{ transform: "translate(-40%, 0%) matrix(1, 0, 0, 1, 0, 0)" }}
												>
													<img src={`${process.env.REACT_APP_BASE_URL}${props.content.Second?.Img.url}`} alt="" />
												</Tween>
											</Timeline>
										)}
									</Scene>
								</Controller>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} md={6}>
						<div className={styles.paragraph}>
							<h3>{props.content.Second?.Sub_title}</h3>
							<p>
								{props.content.Second?.Paragraph}
							</p>
							<button>
								<a href="https://www.youtube.com/watch?v=d2M6HVrE-1w" target="_blank" rel="noreferrer" >
								{props.content.Second?.Button.Button_title}</a></button>
						</div>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};
export default HumbleBeginnings;
