import React, { useEffect, useRef } from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./thirdSection.module.sass";
import { Grid, Container } from "@material-ui/core";
import { ISliders, IParagraphs } from "../../../redux/types";
import { RequestedImage } from "../../../redux/types/defaultTypes";
import { Link } from "react-router-dom";

interface IThirdSection {
	textColor: string | undefined;
	slides: ISliders[];
	settings: Settings;
	sectionNumber: string;
	sectionTitle: string;
	sectionSubTitle: string;
	sectionParagraphs?: IParagraphs[];
	// BGColor: string;
	classes?: string[];
	button?: {
		button_text: string;
		button_url?: string;
	};
}

function ThirdSection(props: IThirdSection) {
	const slider = useRef<Slider>(null);
	useEffect(() => {
		if (slider.current) {
		}
	}, [props, slider]);
	return (
		<div data-color={props.textColor ?? '#fff'}
			 className={`${props.classes?.join(" ")} ${styles.sectionContainer}`}
			// style={{ backgroundColor: props.BGColor }}
		>
			<Container>
				<Grid container alignItems="center">
					<Grid item xs={12} md={12}>
						<p className={styles.sectionName}>
							<span>{props.sectionNumber}</span> {props.sectionSubTitle}
						</p>
						<Grid container justify="space-between">
							<Grid item xs={12} md={7}>
								<h2>{props.sectionTitle.split('\n')[0]} </h2>
								<h2 className={styles.sectionTitle2}>{props.sectionTitle.split('\n')[1]}</h2>
								{props.sectionParagraphs?.map((paragraph) => (
									<p key={paragraph.id}>{paragraph.paragraph}</p>
								))}
							</Grid>
							<Grid item xs={12} md={3} className={styles.btn}>
								{props.button ? <button><Link to="/contactUs">{props.button.button_text}</Link></button> : null}
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Slider ref={slider} {...props.settings} className={styles.sliderContainer}>
							{props.slides.map((slide: ISliders) => {
								return (
									<div key={slide.id} className={styles.slide}>
										<div>
											{slide.slider_images.map((image: RequestedImage) => {
												return (
													<div key={image.id}>
														{/* <img src={`${process.env.REACT_APP_BASE_URL}${image.url}`} alt="slider img" /> */}
													</div>
												);
											})}
										</div>
										{slide.title ? <h2>{slide.title}</h2> : null}
										{slide.paragraph ? <p>{slide.paragraph}</p> : null}
									</div>
								);
							})}
						</Slider>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}

export default ThirdSection;
