import React, { useEffect, useState } from "react";
import styles from "./newInner.module.sass";
import { faFacebookF, faPinterestP, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faLongArrowAltRight} from '@fortawesome/free-solid-svg-icons'
// import FacebookIcon from "@material-ui/icons/Facebook";
// import PinterestIcon from "@material-ui/icons/Pinterest";
// import LinkedInIcon from "@material-ui/icons/LinkedIn";
// import TwitterIcon from "@material-ui/icons/Twitter";
// import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
// import {TrendingFlat} from "@material-ui/icons";
import ReactMarkdown from 'react-markdown';
import { Iarticle,INewsSection } from '../../../redux/types';
import { useParams } from 'react-router-dom';
import {Container } from "@material-ui/core";
import { useHistory} from 'react-router-dom';
import {Helmet} from "react-helmet";


interface NewsSection{
    content: INewsSection;
}
interface RouteParams {
    id: string
}
const NewsInner = (props: NewsSection) => {
	const params = useParams<RouteParams>();
	let history = useHistory();

const handleBackIcon=()=>{
    history.push("/news");

}
	const [selectedNew, setSelectedNew] = useState<Iarticle>();
	console.log(params.id);
    const newSelected=props.content.articles.find((i) =>i.title.toString().replace(/[^a-zA-Z ]/g, "").replace(/\s/g,"-") === params.id);
	console.log(newSelected?.title)
	useEffect(()=>{
		setSelectedNew(newSelected);
	},[newSelected])
	return (
		<div className={styles.newsInnerContainer}>
			 <Helmet>
			 {/* General tags */}
			{/* <title>{selectedNew?.title}</title> */}
			<meta name="description" content={selectedNew?.title}/>		
				<meta property="og:description" content={selectedNew?.paragraph} />
			{/* OpenGraph tags */}
			<meta property="og:title" content={selectedNew?.title}/>
			<meta property="og:image" content={`https://api.theappconcept.com/uploads/${selectedNew?.img.url}`} />
			<meta property="og:type" content="blog" /> 

			</Helmet>	
			<Container>
									<FontAwesomeIcon icon={faLongArrowAltRight} className={styles.backIconForNews} 
									onClick={handleBackIcon}
									 />
			{/* <TrendingFlat className={styles.backIconForNews} onClick={props.handleBackIcon} /> */}
			<h2>{selectedNew?.title}</h2>
			<span>
				<i>{selectedNew?.sub_title}</i>
			</span>
			<div className={styles.typeAndDateContainer}>
				<h3>{selectedNew?.type}</h3>
				<h3>{selectedNew?.date}</h3>
				<div>
					<a href="/" target="_blank" rel="noreferrer">
						{/* <FacebookIcon /> */}
						<FontAwesomeIcon icon={faFacebookF} />

					</a>
					<a href="/" target="_blank" rel="noreferrer">
						{/* <PinterestIcon /> */}
						<FontAwesomeIcon icon={faPinterestP} />

					</a>
					<a href="/" target="_blank" rel="noreferrer">
						{/* <LinkedInIcon /> */}
						<FontAwesomeIcon icon={faLinkedinIn} />

					</a>
					<a href="/" target="_blank" rel="noreferrer">
						{/* <TwitterIcon /> */}
						<FontAwesomeIcon icon={faTwitter} />

					</a>
				</div>
			</div>
			<div>
				<img src={`${process.env.REACT_APP_BASE_URL}${selectedNew?.img.url}`} alt="" />
			</div>
			<ReactMarkdown source={selectedNew?.paragraph ?`${selectedNew?.paragraph}`:""} />
			<p>TAGS: <span><i>{selectedNew?.sub_title}</i></span></p>
			<h3>SHARE ARTICLE:</h3>
			<a href="/">
						{/* <FacebookIcon /> */}
						<FontAwesomeIcon icon={faFacebookF} />
					</a>
					<a href="/">
						{/* <PinterestIcon /> */}
						<FontAwesomeIcon icon={faPinterestP} />
					</a>
					<a href="/">
						{/* <LinkedInIcon /> */}
						<FontAwesomeIcon icon={faLinkedinIn} />

					</a>
					<a href="/">
						{/* <TwitterIcon /> */}
						<FontAwesomeIcon icon={faTwitter} />

					</a>
					</Container>
		</div>
	);
};
export default NewsInner;
