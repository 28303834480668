import React, { useRef } from 'react'
import FirstSection from './firstSection/firstSection';
import ContactSection from "../home/contactSection/contactSection"
import SubscribeForm from "../subscribeForm/subscribeForm";
import { IServicesSection } from '../../redux/types';
import { useSelector } from 'react-redux';
import { AppState } from '../../redux/reducers';
// interface IColor{
// 	updateHeaderColor: (color: string ) => void;
// 	textColor: string ;

// }
const Services = () => {
    

    const sectionContainer = useRef<HTMLDivElement>(null);
	// useEffect(() => {
	// 	let callback = (
    //   entries: IntersectionObserverEntry[],
    //   observer: object
	// 	): void => {
	// 		entries.forEach((entry) => {
	// 			// console.log(entry.target)
	// 			if (entry.intersectionRatio >= 0.4) {
	// 				// console.log(entry)
	// 				if (entry.target instanceof HTMLDivElement) {
	// 					let textColor = entry.target.dataset.color ?? "#000"; 
	// 					// console.log(textColor)
	// 					updateHeaderColor(textColor);
    //       }
    //     }
    //   });
    // };
    // let observer = new IntersectionObserver(callback, {
    //   threshold: [0.4],
	// 	});
    // let secondSections: Array<Element>;
    // if (
    //   sectionContainer &&
    //   sectionContainer.current &&
    //   sectionContainer.current.children
	// 	) {
	// 		secondSections = Array.from(sectionContainer.current.children);
	// 		// console.log(secondSections)
    //   secondSections.forEach((section) => {
	// 			observer.observe(section);
	// 		});
	// 	}
	// },[updateHeaderColor,sectionContainer])



    const servicesData: IServicesSection[] = useSelector((state: AppState) => state.servicesData);

    return(
        <div ref={sectionContainer}>
        <FirstSection content={ servicesData[0]}/>
            <ContactSection
				// sectionNumber="006"
                // sectionSubTitle="CONTACT US"
				quote="Ready To Get Started With TAC?"
				link={{ linkText: "GET A QUOTE", linkUrl: "" }}
			/>
            <SubscribeForm/>
        </div>
    )
}
export default Services